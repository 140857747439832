<!--
 * @Description: 首页
 * @Author: luocheng
 * @Date: 2021-09-13 13:55:25
 * @LastEditors: 冉桂精 156189868@qq.com
 * @LastEditTime: 2024-11-06 17:53:59
-->
<template>
	<div
		:class="{
			'app-box': +systemConfig.theme_type !== 4,
			'four-news': +systemConfig.theme_type === 4
		}"
		v-loading="loading"
	>
		<template v-if="+systemConfig.theme_type !== 4">
			<template v-if="validData && Array.isArray(validData) && validData.length">
				<div
					class="container"
					v-for="(item, index) in validData"
					:key="index"
					:style="item.listData.length && item.is_show ? '' : 'display:none'"
				>
					<div class="header">
						<h4 class="title">
							{{ $getLocalizedLabel({ zhLabel: item.shortcut_name, enLabel: item.en_shortcut_name }) }}
						</h4>
						<!-- 取消跳转工单中心 -->
					</div>
					<div
						class="content grid-class"
						:style="getStyle()"
						:class="{
							'grid-content': validData && Array.isArray(validData) && validData.length
						}"
					>
						<template v-if="item.listData && Array.isArray(item.listData) && item.listData.length">
							<!-- eslint-disable -->
							<div
								v-for="(items, index) in item.listData"
								:key="index"
								class="content-item-box"
								@click="handleAppClick(items)"
							>
								<div class="content-item">
									<div class="content-icon">
										<!-- 默认 -->
										<i class="iconfont iconzhuomianyingyong" v-if="!items.shortcut_icon"></i>
										<!-- element icon -->
										<i v-else-if="items.shortcut_icon.indexOf('el-icon-') === 0" :class="[items.shortcut_icon]"></i>
										<i
											v-else-if="items.shortcut_icon_bg && items.shortcut_icon_bg !== 'transparent'"
											class="iconfont-item"
											:class="items.shortcut_icon"
											:style="{ background: items.shortcut_icon_bg }"
										></i>
										<svg aria-hidden="true" class="iconfont-svg" v-else-if="!item.shortcut_icon.includes(' ')">
											<use :xlink:href="'#' + getIcon(items.shortcut_icon)" />
										</svg>
									</div>
									<div class="content-text">
										<p class="item-title">
											{{ $getLocalizedLabel({ zhLabel: items.shortcut_name, enLabel: items.en_shortcut_name }) }}
										</p>
										<p class="item-desc" v-if="items.shortcut_desc">
											{{ $getLocalizedLabel({ zhLabel: items.shortcut_desc, enLabel: items.en_shortcut_desc }) }}
										</p>
									</div>
								</div>
							</div>
						</template>
					</div>
				</div>
			</template>
			<el-empty v-else :image-size="80"></el-empty>
		</template>
		<!-- 重归 -->
		<template v-else class="four-news">
			<div class="title">
				我的应用
				<!-- <span class="more"
          >更多 <i class="iconfont iconxiangyoujiantou2"></i
        ></span> -->
			</div>
			<div class="list" v-if="validData && Array.isArray(validData) && validData.length">
				<div
					class="item"
					v-for="(item, index) in validData"
					:key="index"
					:style="item.listData.length && item.is_show ? '' : 'display:none'"
				>
					<div class="item-title">
						{{ $getLocalizedLabel({ zhLabel: item.shortcut_name, enLabel: item.en_shortcut_name }) }}
					</div>

					<div
						class="item-content grid-class"
						v-if="item.listData && Array.isArray(item.listData) && item.listData.length"
					>
						<div class="item-data" v-for="(items, index) in item.listData" :key="index" @click="handleAppClick(items)">
							<div class="data-icon">
								<!-- 默认 -->
								<i class="iconfont iconzhuomianyingyong" v-if="!items.shortcut_icon"></i>
								<!-- element icon -->
								<i v-else-if="items.shortcut_icon.indexOf('el-icon-') === 0" :class="[items.shortcut_icon]"></i>
								<i
									v-else-if="items.shortcut_icon_bg && items.shortcut_icon_bg !== 'transparent'"
									class="iconfont-item"
									:class="items.shortcut_icon"
								></i>
								<svg aria-hidden="true" class="iconfont-svg" v-else-if="!item.shortcut_icon.includes(' ')">
									<use :xlink:href="'#' + getIcon(items.shortcut_icon)" />
								</svg>
							</div>
							<div class="date-text">
								<div class="date-title">
									{{ $getLocalizedLabel({ zhLabel: items.shortcut_name, enLabel: items.en_shortcut_name }) }}
								</div>
								<div class="data-desc">
									{{ $getLocalizedLabel({ zhLabel: items.shortcut_desc, enLabel: items.en_shortcut_desc }) }}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<el-empty description="暂无数据" v-else :image-size="80"></el-empty>
		</template>
	</div>
</template>

<script>
import { dataInterface } from '@/apis/data';
import { infoList, getMenuOriginData } from './config';
import { Empty } from 'element-ui';
import { judgingArchiAPermission, judgingUILogic, openUrl, getLinkParams, formatUrl } from '@/utils/tools';
import { mapState } from 'vuex';
import { initParams } from '@/utils/tools';
import { deepCopy } from '@/utils/tools';

export default {
	name: 'myApp',
	components: {
		'el-empty': Empty
	},
	props: {
		pageUUID: {
			type: String,
			required: false,
			default: ''
		},
		componentList: {
			default: null
		}
	},
	data() {
		return {
			appConfig: [],
			infoList,
			loading: false
		};
	},
	computed: {
		...mapState(['targetArchi', 'componentData', 'systemConfig']),
		validData() {
			let newArray = deepCopy(this.appConfig);
			newArray = newArray.filter((item) => item.listData && Array.isArray(item.listData) && item.listData.length);
			newArray = newArray.filter((item) => {
				if (
					!this.judgingArchiAPermission(item.archi_limit, item.permissions) ||
					!this.judgingUILogic(item.UILogic, this.componentList || this.componentData)
				) {
					return false;
				}
				item.listData = item.listData.filter((element) => {
					if (
						this.judgingArchiAPermission(element.archi_limit, element.permissions) &&
						this.judgingUILogic(element.UILogic, this.componentList || this.componentData)
					) {
						return true;
					}
				});
				if (item.listData && item.listData.length > 0) {
					return true;
				}
			});
			return newArray;
		}
	},
	watch: {
		targetArchi: {
			handler(newV, oldV) {
				if (!newV || !oldV) return;
				this.getShortcuts();
			},
			deep: true,
			immediate: true
		}
	},
	created() {
		this.getShortcuts();
	},
	mounted() {
		this.getStyle();
	},
	methods: {
		judgingArchiAPermission,
		judgingUILogic,
		/**
		 * @desc 通过不同的高度来调整不同的样式
		 */
		getStyle() {
			const div = document.querySelector('.app-box');
			const width = div.offsetWidth;

			if (width > 1800) {
				return 'grid-template-columns: repeat(6, 16.1%);';
			} else if (width < 1800 && width > 1500) {
				return 'grid-template-columns: repeat(5, 19%);';
			} else if (width < 1500 && width > 1000) {
				return 'grid-template-columns: repeat(4, 24%);';
			} else if (width < 1000 && width > 600) {
				return 'grid-template-columns: repeat(3,32%);';
			} else {
				return 'grid-template-columns: repeat(3,32%);';
			}
		},
		/**
		 * @desc: 点击快捷方式(我的应用)
		 * @param {Object} item
		 */
		handleAppClick(item) {
			/* eslint-disable */
			// 情况历史记录
			this.$store.commit('setAppNavHistory', {
				origin: 1,
				history: []
			});
			const {
				shortcut_path,
				app_id,
				shortcut_type = 1, // 快捷方式类型 1 链接(页面) 2 组合 3 应用
				params = [],
				link_params = [],
				gopath = false // 直接进入路由（常用应用）
			} = item;
			if (gopath) {
				this.$router.push({
					path: shortcut_path,
					query: {
						pageUUID: item.pageUUID ?? ''
					}
				});
				return;
			}
			let path = shortcut_path;
			let originData = null;
			// 获取当前应用
			const appList = JSON.parse(sessionStorage.getItem('appList'));
			const currentApp = appList.find((ele) => +ele.id === +app_id);
			if (!currentApp) {
				this.$message.error(this.$t('apps.Index.568824-1'));
				return;
			}
			this.$store.commit('setCurrentApp', currentApp);
			if (shortcut_type === 2) {
				// 组合 获取分组下第一个可跳转菜单
				const groupData = this.getGroupData(shortcut_path, currentApp?.app_orign_data || []);
				const groupPath = this.getGroupIndexPath(groupData?.children_list);
				if (!groupData || !groupPath) {
					this.$message.error(this.$t('apps.Index.568824-2'));
					return;
				}
				path = groupPath?.menu_path;
				originData = groupPath;
			} else if (shortcut_type === 3) {
				// 应用 跳转应用下的首页
				const indexObj = this.getIndexPath(currentApp?.app_orign_data || []);
				if (!indexObj) {
					this.$message.error(this.$t('apps.Index.568824-3'));
					return;
				}
				originData = indexObj;
				path = indexObj.menu_path;
			} else {
				// 普通路由跳转
				originData = getMenuOriginData(path, currentApp?.app_orign_data);
			}
			if (!path || !originData) {
				this.$message.error(this.$t('apps.Index.568824-4'));
				return;
			}
			if (originData) {
				this.$store.commit('setCurrentMenu', {
					level: originData.level,
					menuData: originData
				});
			}
			// 获取参数
			const { param = {}, canPost } = initParams(params);
			const selfParam = getLinkParams(link_params);
			const urlParams = `${formatUrl(param)}${formatUrl(param) ? '&' : ''}${formatUrl(selfParam)}`;
			if (originData.menu_type === 1) {
				// window.open('https://ltsd-bim.com/pc/dataview/show/20')
				// window.open(path.indexOf('http') === 0 ? path : window.origin + path);
				let urls = path.indexOf('http') === 0 ? path : window.origin + path;
				if (urlParams && !urls.toString().includes('?')) {
					urls = `${urls}?`;
				}
				urls = `${urls}${urlParams || ''}`;
				openUrl(urls, urls);
				return;
			}
			if (!canPost) {
				this.$message.error(this.$t('apps.Index.568824-5'));
				return;
			}
			if (+originData.menu_type === 4 && path.indexOf('dataview/show') !== -1) {
				const openurls = path.indexOf('http') === 0 ? path : `${window.origin}${path}`;
				if (urlParams) {
					if (!openurls.toString().includes('?')) {
						openurls = `${openurls}?${urlParams}`;
					} else {
						openurls = `${openurls}${urlParams}`;
					}
				}
				openUrl(openurls, openurls);
			} else {
				this.$router.push({
					path,
					query: {
						...param,
						menu_type: originData.menu_type,
						pageUUID: originData.page_uuid
					}
				});
			}
		},
		/**
		 * @description: svg显示去掉font-family
		 * @param {*} icon
		 * @return {*}
		 */
		getIcon(icon) {
			if (!icon) return '';
			let valArr = icon.split(' ');
			if (valArr.length > 1) {
				return valArr[1];
			} else {
				return icon;
			}
		},
		/**
		 * @desc: 获取应用首页
		 * @param {Array} list route list
		 */
		getIndexPath(list = []) {
			for (let i = 0; i < list.length; i++) {
				const { is_index, is_hide, children_list, menu_type } = list[i];
				if (is_index && !is_hide && (+menu_type === 0 || +menu_type === 4)) {
					return list[i];
				}
				if (children_list && children_list.length) {
					const result = this.getIndexPath(children_list);
					if (result) {
						return result;
					}
				}
			}
			return null;
		},
		/**
		 * @desc: 获取分组数据
		 * @param {String} groupPath
		 * @param {Array} routeList
		 */
		getGroupData(groupPath = '', list = []) {
			for (let i = 0; i < list.length; i++) {
				const { menu_type, menu_path, children_list = [] } = list[i];
				if (+menu_type === 2 && menu_path === groupPath) {
					return list[i];
				}
				if (children_list && children_list.length) {
					const result = this.getGroupData(groupPath, children_list);
					if (result) {
						return result;
					}
				}
			}
			return null;
		},
		/**
		 * @desc: 获取分组中第一个可访问路由
		 * @param {Array} list
		 */
		getGroupIndexPath(list = []) {
			for (let i = 0; i < list.length; i++) {
				const { menu_type, children_list = [] } = list[i];
				if (+menu_type === 0 || +menu_type === 4) {
					return list[i];
				}
				if (children_list && children_list.length) {
					const result = this.getGroupIndexPath(children_list);
					if (result) {
						return result;
					}
				}
			}
			return null;
		},
		/**
		 * @desc: 获取当前项目的快捷方式列表
		 */
		getShortcuts() {
			this.loading = true;
			dataInterface({
				__method_name__: 'dataList',
				object_uuid: 'object62b41ff801edf',
				view_uuid: 'view62b42143d9c8b',
				project_id: process.env.VUE_APP_BASE_ID,
				org_tenantrys_id: this.$GetTargetArchi()?.tenantrys_id || 0,
				transcode: 0
			})
				.then((res) => {
					const arrList = [];
					this.appConfig = res?.data?.data || [];
					this.appConfig.map((el) => {
						if (el.is_group == 1) {
							arrList.push({
								...el,
								listData: []
							});
						}
					});
					this.appConfig.map((el) => {
						arrList.map((item) => {
							if (el.is_group == 0 && el.group_id == item.id) {
								item.listData.push(el);
							}
						});
					});
					this.appConfig = arrList;
					this.getCommonData();
					this.loading = false;
				})
				.catch((err) => {
					console.log(err, '----err');
					this.loading = false;
				});
		},
		/**
		 * @desc 获取常用功能列表
		 */
		getCommonData() {
			dataInterface(
				{
					platform: 'PC'
				},
				`${process.env.VUE_APP_BASE_URL}api/v4/user/commonFunctions`,
				'GET'
			).then((res) => {
				if (res.status === 200) {
					let data = res.data.data;
					this.appConfig.forEach((item) => {
						if (item.app_id === -1) {
							let length = item.listData.length;
							let pathString = '';
							if (item?.not_show_path?.length) {
								for (let i = 0; i < item.not_show_path.length; i++) {
									pathString += item.not_show_path[i].menu_path;
								}
							}
							data = data.filter((item) => !pathString.includes(item.path));
							for (let index = 0; index < 6 - length; index++) {
								if (data[index] && data[index].infos) {
									item.listData.push({
										shortcut_name: JSON.parse(data[index]?.infos)?.shortcut_name,
										shortcut_path: data[index].path,
										shortcut_icon: JSON.parse(data[index]?.infos)?.shortcut_icon,
										id: data[index].appId,
										gopath: true,
										pageUUID: data[index].pageUUID
									});
								}
							}
						}
					});
				}
			});
		},
		/**
		 * @description: 废弃
		 */
		goManageCenter() {
			this.$store.commit('setShowAppCenter', true);
			this.$router.push({
				path: 'work-order',
				query: {
					menu_type: 4
				}
			});
		}
	},
	updated() {
		if (
			document.querySelector('.grid-class').offsetWidth !== 0 &&
			document.querySelector('.grid-class').offsetWidth < 530
		) {
			let divAll = document.querySelectorAll('.grid-class');
			divAll.forEach((item) => {
				item.style.gridTemplateColumns = 'repeat(2, 49%)';
			});
		}
	}
};
</script>

<style lang="less" scoped>
.app-box {
	height: 100%;
	width: 100%;
	background: #ffffff;
	border-radius: 8px;
	padding: 6px 20px 20px 20px;
	box-sizing: border-box;
	overflow: hidden;
	overflow-y: auto;
	scrollbar-width: none;
	:deep(.el-empty) {
		width: 100%;
		height: 100%;
	}
	.container {
		width: 100%;
		padding-top: 10px;
		.header {
			display: flex;
			justify-content: space-between;
			align-items: center;
		}
		.title {
			font-size: 16px;
			font-family: PingFangSC-Medium, PingFang SC;
			color: #2a2f3d;
			letter-spacing: 1px;
		}
		.content {
			padding-top: 12px;
			&.grid-content {
				display: grid;
				grid-template-columns: repeat(3, 31.5%);
				grid-column-gap: 16px;
				grid-row-gap: 16px;
			}
			.content-item-box {
				.content-item {
					padding: 12px 21px 12px 12px;
					border-radius: 8px;
					border: 1px solid #f2f5fc;
					display: flex;
					align-items: center;
					cursor: pointer;
					.content-icon {
						height: 40px;
						width: 40px;
						line-height: 40px;
						box-sizing: border-box;
						text-align: center;
						font-size: 36px;
						i {
							display: block;
							height: 100%;
						}
						.iconfont-svg {
							display: block;
							height: 100%;
							width: 100%;
						}
						.iconfont-item {
							color: #fff;
							width: 40px;
							height: 40px;
							border-radius: 6px;
							line-height: 40px;
							font-size: 24px;
						}
					}
					.item-icon-info {
						width: 21px;
						height: 21px;
						padding: 0 8px;
					}
					.content-text {
						box-sizing: border-box;
						flex: 1;
						padding-left: 12px;
						height: 100%;
						overflow: hidden;
						.item-title {
							height: 22px;
							font-size: 14px;
							font-family: PingFangSC-Regular, PingFang SC;
							font-weight: 400;
							color: #272829;
							line-height: 22px;
						}
						.item-desc {
							height: 18px;
							width: 100%;
							box-sizing: border-box;
							box-sizing: border-box;
							font-size: 12px;
							font-family: PingFangSC-Regular, PingFang SC;
							font-weight: 400;
							color: #969799;
							line-height: 18px;
							overflow: hidden;
							text-overflow: ellipsis;
							white-space: nowrap;
						}
						.item-title,
						.item-desc {
							white-space: nowrap;
							text-overflow: ellipsis;
							overflow: hidden;
						}
					}
				}
				.el-empty {
					margin: 0 auto;
					padding: 20px 0;
				}
			}
		}
	}
	.block {
		display: block;
	}
}
.four-news {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	.title {
		display: flex;
		height: 52px;
		padding: 0px var(--spacing-8, 16px) 0px var(--spacing-10, 20px);
		justify-content: space-between;
		align-items: center;
		color: var(--text-on-surface-primary, #181b22);

		/* web/cn/heading/heading-02 */
		font-family: 'PingFang SC';
		font-size: 16px;
		font-style: normal;
		font-weight: 500;
		line-height: 150%; /* 24px */
		letter-spacing: 0.5px;
		.more {
			color: var(--text-on-surface-tertiary, #707786);
			/* web/number/medium/helper-01 */
			font-family: 'JetBrains Mono NL';
			font-size: 12px;
			font-style: normal;
			font-weight: 500;
			line-height: 150%; /* 18px */
			display: flex;
			align-items: center;
		}
	}
	.list {
		display: flex;
		padding: var(--spacing-10, 20px);
		flex-direction: column;
		align-items: flex-start;
		flex: 1 0 0;
		align-self: stretch;
		border-radius: 16px;
		border: 1px solid rgba(255, 255, 255, 0.6);
		// background: linear-gradient(
		//   180deg,
		//   rgba(255, 255, 255, 0.24) 0%,
		//   rgba(255, 255, 255, 0.6) 100%
		// );
		background: #fff;
		backdrop-filter: blur(13px);
		overflow: auto;
		gap: 20px;
		.item {
			width: 100%;
			height: 100%;
			&-title {
				color: var(--text-on-surface-secondary, #424751);

				/* web/cn/label/label-02 */
				font-family: 'PingFang SC';
				font-size: 12px;
				font-style: normal;
				font-weight: 500;
				line-height: 150%; /* 18px */
				letter-spacing: 0.4px;
				margin-bottom: 16px;
			}
			&-content {
				display: grid;
				grid-template-columns: repeat(3, 31.5%);
				grid-column-gap: 16px;
				grid-row-gap: 16px;
				.item-data {
					display: flex;
					padding: 12px;
					gap: 12px;
					flex: 1 0 0;
					border-radius: 12px;
					border: 1px solid #f9f6f4;
					background: var(--overall-surface-default, #fff);
					&:hover {
						border: 1px solid #f9f6f4;
						background: linear-gradient(
							180deg,
							rgba(247, 171, 25, 0) 2.19%,
							rgba(242, 119, 19, 0.1) 52.39%,
							rgba(231, 0, 6, 0.1) 98.44%
						);
					}
					.data-icon {
						display: flex;
						width: 40px;
						height: 40px;
						justify-content: center;
						align-items: center;
						border-radius: 12px;
						background: #fbf2ef;
						i {
							font-size: 26px;
							background: linear-gradient(
								to top,
								rgba(247, 169, 25, 1),
								rgba(232, 10, 8, 1)
							); /*设置渐变的方向从左到右 颜色从ff0000到ffff00*/
							-webkit-background-clip: text; /*将设置的背景颜色限制在文字中*/
							-webkit-text-fill-color: transparent; /*给文字设置成透明*/
						}
					}
					.date-text {
						display: flex;
						flex-direction: column;
						overflow: hidden;
						text-overflow: ellipsis;
						white-space: nowrap;

						.date-title {
							color: var(--text-on-surface-primary, #181b22);
							overflow: hidden;
							text-overflow: ellipsis;
							white-space: nowrap;
							/* web/cn/body/body-01 */
							font-family: 'PingFang SC';
							font-size: 14px;
							font-style: normal;
							font-weight: 400;
							line-height: 22px; /* 157.143% */
							letter-spacing: 0.5px;
						}
						.data-desc {
							color: var(--text-on-surface-tertiary, #707786);
							overflow: hidden;
							text-overflow: ellipsis;
							white-space: nowrap;
							/* web/cn/helper/helper-02 */
							font-family: 'PingFang SC';
							font-size: 12px;
							font-style: normal;
							font-weight: 400;
							line-height: 150%; /* 18px */
							letter-spacing: 0.4px;
						}
					}
				}
			}
		}
	}
}
</style>

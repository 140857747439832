<!--
 * @Description: 顶部
 * @Author: luocheng
 * @Date: 2022-06-17 18:32:08
 * @LastEditors: 冉桂精 156189868@qq.com
 * @LastEditTime: 2024-11-06 19:02:12
-->
<template>
	<div class="header">
		<div class="header-logo">
			<img :src="systemLogo || defaultLogo" alt="" class="logo" />
		</div>
		<section class="search">
			<el-autocomplete
				ref="el_auto"
				:popper-class="noData ? 'platform-auto-complete' : ''"
				v-model="state"
				:fetch-suggestions="querySearch"
				:placeholder="$t('placeholder')"
				clearable
				prefix-icon="el-icon-search"
				@select="handleSelect"
				@clear="clearSelect"
				@focus="handleFocus"
				:popper-append-to-body="false"
			>
				<template
					slot-scope="{ item }"
					v-if="
						judgingArchiAPermission(item.archi_limit, item.permissions) &&
						judgingUILogic(item.UILogic, componentList || componentData)
					"
				>
					<div class="content-item" v-if="!noData">
						<div class="content-icon" v-if="item.shortcut_icon">
							<!-- 默认 -->
							<i class="iconfont iconzhuomianyingyong" v-if="!item.shortcut_icon"></i>
							<!-- element icon -->
							<i v-if="item.shortcut_icon.indexOf('el-icon-') === 0" :class="[item.shortcut_icon]"></i>

							<svg aria-hidden="true" class="iconfont-svg" v-else>
								<use :xlink:href="'#' + item.shortcut_icon" />
							</svg>
						</div>
						<div class="content-icon" v-else>
							<i
								:class="['item-icon', item.icon]"
								v-if="item.icon && item.icon.toString().indexOf('el-icon') === 0"
							></i>
							<i v-else :class="['item-icon', 'iconfont', item.icon || 'iconyeqian']"></i>
						</div>

						<div class="content-text">
							<p class="item-title">
								{{ item.shortcut_name ? item.shortcut_name : item.name }}
							</p>
						</div>
					</div>
					<div v-else class="default">
						{{ item.default }}
					</div>
				</template>
			</el-autocomplete>
		</section>
		<div class="header-right">
			<!-- <WeatherCom :element="{statusConfig: {showWeather: true, showAirpm: true, showIcon: true}}"></WeatherCom> -->
			<!-- 天气 -->
			<HeaderWeather v-if="needWeather"></HeaderWeather>
			<!-- 选择架构 -->
			<SelectArchi v-if="needArchiInfo"></SelectArchi>
			<!-- 五方 -->
			<Cooperation v-if="needFiveParties"></Cooperation>
			<!-- APP下载二维码 -->
			<appQrcode v-if="needAppQrcode"></appQrcode>
			<UserAction></UserAction>
			<TranslateSwitch v-if="systemConfig.enable_multi_language" status="secondary"></TranslateSwitch>
		</div>
	</div>
</template>

<script>
import SelectArchi from './SelectArchi';
import Cooperation from '@/views/appEntry/components/Cooperation';
import UserAction from '@/views/appEntry/components/UserActions';
import { dataInterface } from '@/apis/data';
import { judgingArchiAPermission, judgingUILogic, openUrl } from '@/utils/tools';
import { getMenuOriginData } from '../apps/config';
import { mapState } from 'vuex';
import { Autocomplete } from 'element-ui';
import { initParams } from '@/utils/tools';
import appQrcode from '@/views/newSecond/components/appQrcode/Index';
import HeaderWeather from './HeaderWeather.vue';
import TranslateSwitch from '@/views/newSecond/components/translateSwitch/index.vue';
export default {
	name: 'Header',
	components: {
		SelectArchi,
		UserAction,
		Cooperation,
		'el-autocomplete': Autocomplete,
		appQrcode,
		HeaderWeather,
		TranslateSwitch
	},
	data() {
		return {
			filterText: '',
			defaultLogo: require('@/assets/images/dev_logo.png'),
			restaurants: [], // 赛选列表
			menuListData: [],
			noData: false, // 是否匹配到数据
			shortcutAppList: [], // 快捷应用列表
			state: '',
			componentList: {
				default: null
			}
		};
	},
	computed: {
		...mapState(['projectEnvironment', 'systemConfig', 'componentData', 'showMenuList']),
		systemLogo() {
			return this.systemConfig?.logo || this.defaultLogo;
		},
		needFiveParties() {
			return !!this.systemConfig?.need_five_parties;
		},
		needAppQrcode() {
			return !!this.systemConfig?.app_downLoad_Qrcode;
		},
		needArchiInfo() {
			const { need_archi_info = 1 } = this.systemConfig?.need_archi_info;
			return !!need_archi_info;
		},
		needWeather() {
			return !!this.systemConfig?.need_weather;
		}
	},
	methods: {
		judgingArchiAPermission,
		judgingUILogic,
		/**
		 * @description: 搜索功能/应用
		 * @param {*} queryString
		 * @param {*} cb
		 * @return {*}
		 */
		querySearch(queryString, cb) {
			let restaurants = this.restaurants;
			let results = queryString ? restaurants.filter(this.createFilter(queryString)) : restaurants;
			this.noData = false;
			if (results.length === 0) {
				results = [{ default: '无匹配结果' }];
				this.noData = true;
			}
			cb(results); // 调用 callback 返回建议列表的数据
		},
		/**
		 * @desc: 搜索框筛选
		 * @param {*} queryString
		 * @return {*}
		 */
		createFilter(queryString) {
			return (restaurant) => {
				return (
					(restaurant.shortcut_name ? restaurant.shortcut_name : restaurant.name)
						.toLowerCase()
						.indexOf(queryString.toLowerCase()) > -1
				);
			};
		},
		/**
		 * @desc: 获取当前项目的快捷方式列表
		 */
		getShortcuts() {
			this.loading = true;
			dataInterface({
				__method_name__: 'dataList',
				object_uuid: 'object62b41ff801edf',
				view_uuid: 'view62b42143d9c8b',
				project_id: process.env.VUE_APP_BASE_ID,
				org_tenantrys_id: this.$GetTargetArchi()?.tenantrys_id || 0,
				transcode: 0
			})
				.then((res) => {
					const arrList = [];
					this.appConfig = res?.data?.data || [];
					this.shortcutAppList = res.data?.data;
					this.appConfig.map((el) => {
						if (el.is_group == 1) {
							arrList.push({
								...el,
								listData: []
							});
						}
					});
					this.appConfig.map((el) => {
						arrList.map((item) => {
							if (el.is_group == 0 && el.group_id == item.id) {
								item.listData.push(el);
							}
						});
					});
					this.appConfig = arrList;
					let shortcutAppArr = [];
					arrList.map((item) => {
						shortcutAppArr.push(...item.listData);
					});
					const appList = JSON.parse(sessionStorage.getItem('appList'));
					let arr = [];
					let addData = [];
					for (let i = 0; i < appList.length; i++) {
						addData.push(...appList[i].app_orign_data);
					}
					shortcutAppArr.map((item) => {
						arr.push(this.getMenuData(item.menu_id, addData));
					});
					this.menuListData = [];
					this.restaurants = [];
					this.getMenuList(arr);
					if (this.menuListData.length && Array.isArray(this.menuListData)) {
						this.restaurants.push(...shortcutAppArr, ...this.removelSameData(this.menuListData));
					}
					this.loading = false;
				})
				.catch((err) => {
					console.log(err, '---111-err');
					this.loading = false;
				});
		},
		/**
		 * @desc: 解决关闭后不列出筛选框
		 */
		clearSelect() {
			this.$refs.el_auto.activated = true;
		},
		handleFocus() {
			this.getShortcuts();
		},
		/**
		 * @desc: 点击快捷方式(我的应用)
		 * @param {Object} item
		 */
		async handleSelect(item) {
			const appList = JSON.parse(sessionStorage.getItem('appList'));
			/* eslint-disable */
			if (item.shortcut_name) {
				const {
					shortcut_path,
					app_id,
					shortcut_type = 1, // 快捷方式类型 1 链接(页面) 2 组合 3 应用
					params = []
				} = item;
				let path = shortcut_path;
				let originData = null;
				// 获取当前应用
				const currentApp = appList.find((ele) => +ele.id === +app_id);
				if (!currentApp) {
					this.$message.error(this.$t('apps.Index.568824-1'));
					return;
				}
				this.$store.commit('setCurrentApp', currentApp);
				if (shortcut_type === 2) {
					// 组合 获取分组下第一个可跳转菜单
					const groupData = this.getGroupData(shortcut_path, currentApp?.app_orign_data || []);
					const groupPath = this.getGroupIndexPath(groupData?.children_list);
					if (!groupData || !groupPath) {
						this.$message.error(this.$t('apps.Index.568824-2'));
						return;
					}
					path = groupPath?.menu_path;
					originData = groupPath;
				} else if (shortcut_type === 3) {
					// 应用 跳转应用下的首页
					const indexObj = this.getIndexPath(currentApp?.app_orign_data || []);
					if (!indexObj) {
						this.$message.error(this.$t('apps.Index.568824-3'));
						return;
					}
					originData = indexObj;
					path = indexObj.menu_path;
				} else {
					// 普通路由跳转
					originData = getMenuOriginData(path, currentApp?.app_orign_data);
				}
				if (!path || !originData) {
					this.$message.error(this.$t('apps.Index.568824-4'));
					return;
				}
				if (originData) {
					this.$store.commit('setCurrentMenu', {
						level: originData.level,
						menuData: originData
					});
				}
				if (originData.menu_type === 1) {
					// window.open('https://ltsd-bim.com/pc/dataview/show/20')
					// window.open(path.indexOf("http") === 0 ? path : window.origin + path);
					const urls = path.indexOf('http') === 0 ? path : window.origin + path;
					openUrl(urls, urls);
					return;
				}
				// 获取参数
				const { param = {}, canPost } = initParams(params);
				if (!canPost) {
					this.$message.error(this.$t('apps.Index.568824-5'));
					return;
				}
				if (+originData.menu_type === 4 && path.indexOf('dataview/show') !== -1) {
					// window.open(
					//   path.indexOf("http") === 0
					//     ? path
					//     : `${window.origin}${path}${this.formatUrl(param)}`
					// );
					const openurls = ath.indexOf('http') === 0 ? path : `${window.origin}${path}${this.formatUrl(param)}`;
					openUrl(openurls, openurls);
				} else {
					this.$router.push({
						path,
						query: {
							...param,
							menu_type: originData.menu_type,
							pageUUID: originData.page_uuid
						}
					});
				}
			} else {
				let addData = [];
				for (let i = 0; i < appList.length; i++) {
					addData.push(...appList[i].app_orign_data);
				}
				let parentList = [];
				parentList = this.getParentData(item.id, addData);
				let app_id = parentList[parentList.length - 1].id;
				const currentApp = appList.find((ele) => +ele.app_id === +app_id);
				if (!currentApp) {
					this.$message.error(this.$t('apps.Index.568824-1'));
					return;
				}
				this.$store.commit('setCurrentApp', currentApp);
				let data = null;
				if (Array.isArray(item.children_list) && item.children_list.length) {
					// 组合 获取分组下第一个可跳转菜单
					const groupPath = this.getGroupIndexPath(item.children_list);
					if (!groupPath) {
						this.$message.error(this.$t('apps.Index.568824-2'));
						return;
					}
					data = groupPath;
				}
				let originData = data || item;
				this.$router
					.push({
						path: originData.menu_path,
						query: {
							menu_type: originData.menu_type,
							pageUUID: originData.page_uuid
						}
					})
					.catch((err) => {
						console.log(err);
					});
			}
		},
		/**
		 * @description: 格式化url
		 * @param {Object} obj
		 */
		formatUrl(obj) {
			let url = '';
			for (let key in obj) {
				url = `${url}${key}=${obj[key]}&`;
			}
			return url;
		},
		/**
		 * @desc: 获取应用首页
		 * @param {Array} list route list
		 */
		getIndexPath(list = []) {
			for (let i = 0; i < list.length; i++) {
				const { is_index, is_hide, children_list, menu_type } = list[i];
				if (is_index && !is_hide && (+menu_type === 0 || +menu_type === 4)) {
					return list[i];
				}
				if (children_list && children_list.length) {
					const result = this.getIndexPath(children_list);
					if (result) {
						return result;
					}
				}
			}
			return null;
		},
		/**
		 * @desc: 获取分组数据
		 * @param {String} groupPath
		 * @param {Array} routeList
		 */
		getGroupData(groupPath = '', list = []) {
			for (let i = 0; i < list.length; i++) {
				const { menu_type, menu_path, children_list = [] } = list[i];
				if (+menu_type === 2 && menu_path === groupPath) {
					return list[i];
				}
				if (children_list && children_list.length) {
					const result = this.getGroupData(groupPath, children_list);
					if (result) {
						return result;
					}
				}
			}
			return null;
		},
		/**
		 * @desc: 获取菜单定级父级数据
		 * @param {String} itemId
		 * @param {Array} appList
		 */
		getParentData(itemId = '', appList = []) {
			for (let i = 0; i < appList.length; i++) {
				const { id, children_list = [] } = appList[i];
				if (+itemId === +id) {
					return [appList[i]];
				}
				if (children_list && children_list.length) {
					let result = this.getParentData(itemId, children_list);
					if (result) {
						return result.concat(appList[i]);
					}
				}
			}
			return null;
		},
		/**
		 * @desc: 获取分组中第一个可访问路由
		 * @param {Array} list
		 */
		getGroupIndexPath(list = []) {
			for (let i = 0; i < list.length; i++) {
				const { menu_type, children_list = [] } = list[i];
				if (+menu_type === 0 || +menu_type === 4) {
					return list[i];
				}
				if (children_list && children_list.length) {
					const result = this.getGroupIndexPath(children_list);
					if (result) {
						return result;
					}
				}
			}
			return null;
		},
		/**
		 * @desc: 获取菜单数据
		 * @return {*}
		 */
		getMenuData(menuListid = '', appList = []) {
			for (let i = 0; i < appList.length; i++) {
				const { id, children_list = [] } = appList[i];
				if (+id === +menuListid) {
					return appList[i];
				}
				if (children_list && children_list.length) {
					const result = this.getMenuData(menuListid, children_list);
					if (result) {
						return result;
					}
				}
			}
			return null;
		},
		/**
		 * @desc: 获取所有功能数据
		 * @param {Array} appList
		 */
		getMenuList(appList = []) {
			for (let i = 0; i < appList.length; i++) {
				if (!appList[i]) {
					continue;
				}
				const { level, is_hide, archi_limit, permissions, children_list = [] } = appList[i];
				if ((5 === +level || 4 === +level) && !is_hide && judgingArchiAPermission(archi_limit, permissions)) {
					this.menuListData.push(appList[i]);
				}
				if (children_list && children_list.length && !is_hide) {
					this.getMenuList(children_list);
				}
			}
			return null;
		},
		/**
		 * @desc: 去除重复数据
		 * @param {*} list
		 * @return {*}
		 */
		removelSameData(list = []) {
			if (!Array.isArray(list)) {
				return;
			}
			let arrayId = [];
			let array = [];
			for (let i = 0; i < list.length; i++) {
				if (arrayId.indexOf(list[i].id) === -1) {
					arrayId.push(list[i].id);
					array.push(list[i]);
				}
			}
			return array;
		}
	},
	mounted() {
		this.getShortcuts();
	}
};
</script>

<style lang="less" scoped>
@headerHeight: 104px;
.header {
	display: flex;
	width: 100%;
	height: @headerHeight;
	box-sizing: border-box;
	padding: 26px 16px;
	.header-logo {
		width: auto;
		min-width: 20%;
		.logo {
			height: 52px;
			// max-width: 400px;
			margin-right: 30px;
		}
	}
	.search {
		flex: 1;
		display: flex;
		justify-content: center;
		box-sizing: border-box;
		padding: 10px 0;
		.content-item {
			display: flex;
			align-items: center;
			.content-icon {
				width: 18px;
				height: 16px;
				line-height: 16px;
				margin-right: 12px;
				box-sizing: border-box;
				text-align: center;
				i {
					display: block;
					height: 100%;
				}
				.iconfont-svg {
					display: block;
					height: 100%;
					width: 100%;
				}
			}
			.content-text {
				.item-title {
					font-family: 'PingFang SC';
					font-style: normal;
					font-weight: 400;
					font-size: 14px;
					letter-spacing: 0.5px;
					color: #161c1f;
				}
			}
		}
		:deep(.el-input) {
			display: block;
			margin: 0 auto;
			width: 400px;
			background: #d7f2ff;
			border-radius: 4px;
			opacity: 0.8px;
			border: none;
			.el-input__icon {
				color: #4589ff;
			}
			input {
				background: #d7f2ff;
			}
			input::-webkit-input-placeholder {
				color: #4589ff;
				font-size: 12px;
			}
		}
		:deep(.el-autocomplete-suggestion__wrap) {
			min-height: 320px;
		}
		:deep(.popper__arrow) {
			display: none;
		}
		:deep(.el-autocomplete-suggestion) {
			border-radius: 6px;
			margin-top: 8px;
		}
		:deep(.el-autocomplete-suggestion li) {
			padding: 0 16px;
			&:hover {
				background-color: #eff1f5;
			}
		}
		:deep(.platform-auto-complete li) {
			pointer-events: none; // 阻止可点击事件
			text-align: center;
			&:hover {
				background-color: #fff;
			}
		}
	}
	.header-right {
		width: auto;
		// max-width: 40%;
		display: flex;
		align-items: center;
		justify-content: flex-end;
	}
}
</style>

<template>
  <div
    v-if="+systemConfig.theme_type !== 4"
    class="todo-com-container"
    v-loading="loading"
  >
    <div class="todo-com-header">
      <div class="todo-tabs">
        <div class="item" @click.stop="handleTab('tab1')">
          <span class="text" :class="activeTab === 'tab1' ? 'active' : ''">{{
            $t('todoCom.index.735398-0')
          }}</span>
          <span class="num-label">{{ todoListTotal }}</span>
        </div>
        <div class="item" @click.stop="handleTab('tab2')">
          <span class="text" :class="activeTab === 'tab2' ? 'active' : ''">{{
            $t('todoCom.index.735398-1')
          }}</span>
          <span class="num-label">{{ warringTotal }}</span>
        </div>
        <div class="item" @click.stop="handleTab('tab3')">
          <span class="text" :class="activeTab === 'tab3' ? 'active' : ''">{{
            $t('todoCom.index.735398-2')
          }}</span>
          <span class="num-label">{{ copyMeListTotal }}</span>
        </div>
      </div>
      <!-- <div class="more">
        <i class="iconfont iconxiangyoujiantou2"></i>
      </div> -->
    </div>
    <section class="todo-main-warp">
      <TodoList
        v-if="activeTab === 'tab1'"
        :list="todoList"
        @send-scroll-event="handleScrollEvent"
        @refreshList="getTodoList"
      />
      <WarringList
        v-if="activeTab === 'tab2'"
        :list="warringList"
        @refresh="getWarringList(true)"
        @send-scroll-event="handleScrollEvent"
      />
      <copyMeList
        v-if="activeTab === 'tab3'"
        :list="copyMeList"
        @send-scroll-event="handleScrollEvent"
        @refreshList="getCopyList"
      />
    </section>
  </div>
  <div v-else class="four-news">
    <div class="title">
      {{ $t('todoCom.index.735398-4') }}
      <!-- <span class="more"
        >更多 <i class="iconfont iconxiangyoujiantou2"></i
      ></span> -->
    </div>
    <div class="list">
      <div class="category">
        <div
          :class="{ item: true, active: activeTab === item.tab }"
          v-for="item in categoryList"
          :key="item.label"
          @click="activeTab = item.tab"
        >
          <div class="icon" :style="{ background: item.background }">
            <img :src="item.icon" alt="" />
          </div>
          <div class="text">
            {{ item.label }}
            <span class="num" :style="{ background: item.color }">18</span>
          </div>
        </div>
      </div>
      <TodoList
        v-if="activeTab === 'tab1'"
        :list="todoList"
        :loading="todoLoading"
        @send-scroll-event="handleScrollEvent"
        @refreshList="getTodoList"
      />
      <WarringList
        v-if="activeTab === 'tab2'"
        :list="warringList"
        v-loading="warringLoading"
        @refresh="getWarringList(true)"
        @send-scroll-event="handleScrollEvent"
      />
      <copyMeList
        v-if="activeTab === 'tab3'"
        :list="copyMeList"
        :loading="copyMeloading"
        @send-scroll-event="handleScrollEvent"
        @refreshList="getCopyList"
      />
    </div>
  </div>
</template>

<script>
import TodoList from './TodoList.vue';
import copyMeList from './copyMeList.vue';
import WarringList from './WarringList.vue';
import { getNewHomeList, getHomeTODOList, getTODOStatusNum } from '@/apis/home';
import { dataInterface } from '@/apis/data';
import { mapState } from 'vuex';
import eventBus from '@/plugins/eventBus';
export default {
  components: { TodoList, WarringList, copyMeList },
  props: {
    // archiID: {
    //   type: [String, Number],
    //   default: ''
    // }
  },
  data() {
    return {
      activeTab: 'tab1',
      todoList: [],
      todoListTotal: 0,
      warringList: [],
      warringPage: 0,
      warringTotal: 0,
      copyMeList: [],
      copyMeListTotal: 0,
      todoLoading: false,
      warringLoading: false,
      copyMeloading: false,
      warringListIsEnd: false,
      loading: false,
      isUpdate: true, // 是否到底
      warningTotal: [],
      page: 1,
      size: 20,
      total: 0,
      timer: '',
      last_page: 1,
      categoryList: [
        {
          label: this.$t('todoCom.index.735398-0'),
          icon: 'https://openim-1309784708.cos.ap-shanghai.myqcloud.com/c0e1612e8472c4b85938c60ca1fd31e3.png',
          background: '#FBF8EF',
          color: 'linear-gradient(180deg, #F36600 0%, #F7C619 100%)',
          tab: 'tab1',
        },
        {
          label: this.$t('todoCom.index.735398-1'),
          icon: 'https://openim-1309784708.cos.ap-shanghai.myqcloud.com/7a45bfba88334d2a233411c920168b4c.png',
          background: '#FBF2EF',
          color: 'linear-gradient(180deg, #E80A08 0%, #F7A919 100%)',
          tab: 'tab2',
        },
        {
          label: this.$t('todoCom.index.735398-2'),
          icon: 'https://openim-1309784708.cos.ap-shanghai.myqcloud.com/9c586c9e4c1fea0c0cc71fda01569bb9.png',
          background: '#EFFBFB',
          color: 'linear-gradient(180deg, #009FA9 0%, #00C7AF 100%)',
          tab: 'tab3',
        },
      ],
    };
  },
  computed: {
    ...mapState(['targetArchi', 'systemConfig']),
  },
  watch: {
    targetArchi: {
      handler() {
        this.getTodoList(true);
        this.getWarringList(true);
      },
      deep: true,
      immediate: true,
    },
  },
  created() {
    this.getTodoList(true);
    this.getWarringList(true);
    this.getCopyList(true);
  },
  mounted() {
    /**
     * @desc: 操作完毕关闭弹窗
     */
    eventBus.$on('updateTodoList', () => {
      this.getTodoList();
    });
  },
  methods: {
    getTodoList(bool) {
      this.loading = true;
      if (bool) {
        this.todoList = [];
        this.page = 1;
        getTODOStatusNum().then((res) => {
          if (res.data.code === 200) {
            this.copyMeListTotal = res.data.data.CCToMeUnread;
          }
        });
      }
      const data = {
        todo_type: 1,
        page: this.page,
        size: this.size,
      };
      getHomeTODOList(data)
        .then((res) => {
          this.loading = false;
          if (res.data.code === 200) {
            this.todoListTotal = res.data.data.total;
            this.last_page = res.data.data.last_page;
            let list = res.data.data.data || [];
            if (Array.isArray(list) && list.length) {
              list.forEach((item) => {
                this.todoList.push(item);
              });
            } else {
              if (list.length === 0 && this.page > 1) {
                this.isUpdate = false;
                this.$message.warning(this.$t('todoCom.index.735398-3'));
              }
            }
          }
        })
        .catch(() => (this.loading = false));
    },

    getCopyList(bool) {
      this.loading = true;
      if (bool) {
        this.copyMeList = [];
        this.page = 1;
        getTODOStatusNum().then((res) => {
          if (res.data.code === 200) {
            this.copyMeListTotal = res.data.data.CCToMeUnread;
          }
        });
      }
      const data = {
        todo_type: 3,
        size: this.size,
        page: this.page,
      };
      getHomeTODOList(data)
        .then((res) => {
          this.loading = false;
          if (res.data.code === 200) {
            this.last_page = res.data.data.last_page;
            let list = res.data.data.data || [];
            if (Array.isArray(list) && list.length) {
              list.forEach((item) => {
                this.copyMeList.push(item);
              });
            } else {
              if (list.length === 0 && this.page > 1) {
                this.isUpdate = false;
                this.$message.warning(this.$t('todoCom.index.735398-3'));
              }
            }
          }
        })
        .catch(() => (this.loading = false));
    },

    getWarringList(bool) {
      this.loading = true;
      if (bool) {
        this.warringList = [];
        this.page = 1;
      }
      const reqData = {
        __method_name__: 'dataList',
        transcode: 0,
        object_uuid: 'object635201835e212',
        view_uuid: 'view63a85fe0ba34c',
        size: this.size,
        page: this.page,
      };
      if (this.targetArchi) {
        reqData.archi_id = this.targetArchi.id;
      }
      dataInterface({}, 'api/graph/1288')
        .then((res) => {
          this.loading = false;
          if (res.data.code === 200) {
            const datList =
              res.data.data?.filter((item) => +item.is_read === 0) || [];
            this.last_page = Math.ceil(datList.length / this.size);
            /* bool值决定了是否重新刷新数据 */
            if (bool) {
              this.warringList = [];
              this.page = 1;
            }
            let list =
              datList.slice(
                (this.page - 1) * this.size,
                this.page * this.size
              ) || [];
            let warringList = this.warringList;
            if (Array.isArray(list) && list.length) {
              list.forEach((item) => {
                warringList.push(item);
              });
              this.warringList = warringList;
              this.warringTotal = datList.length;
            } else {
              if (list.length === 0 && this.page > 1) {
                this.isUpdate = false;
                this.$message.warning(this.$t('todoCom.index.735398-3'));
              }
            }
          }
        })
        .catch(() => (this.loading = false));
    },
    /**
     * @desc: 滚动加载
     * @param {Object} e
     */
    handleScrollEvent(e) {
      if (
        e.target.offsetHeight + e.target.scrollTop - e.target.scrollHeight >
          -10 &&
        this.page < this.last_page
      ) {
        if (this.isUpdate) {
          this.page += 1;
          if (this.activeTab === 'tab1') {
            this.getTodoList();
          } else if (this.activeTab === 'tab2') {
            this.getWarringList();
          } else {
            this.getCopyList();
          }
        } else {
          if (this.timer) {
            return;
          }
          this.timer = setTimeout(() => {
            this.$message.warning(this.$t('todoCom.index.735398-3'));
            this.timer = null;
          }, 1000);
        }
      }
    },
    handleTab(tab) {
      this.activeTab = tab;
      if (tab === 'tab1') {
        this.getTodoList(true);
      } else if (tab === 'tab2') {
        this.getWarringList(true);
      } else {
        this.getCopyList(true);
      }
    },
  },
};
</script>
<style lang="less" scoped>
.todo-com-container {
  width: 100%;
  height: 100%;
  border-radius: 8px;
  background: #fff;
  padding: 12px 10px;
  box-sizing: border-box;
  .todo-com-header {
    height: 32px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 4px 10px;
    box-sizing: border-box;
    .todo-tabs {
      height: 100%;
      display: flex;
      align-items: center;
      overflow: hidden;
      .item {
        display: flex;
        align-items: center;
        cursor: pointer;
        flex-shrink: 0;
        .text {
          font-size: 16px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 600;
          color: #6d768f;
          line-height: 24px;
          letter-spacing: 1px;
          &:hover {
            color: #409eff;
          }
        }
        .active {
          color: #2a2f3d;
        }
        .num-label {
          margin-left: 8px;
          height: 18px;
          line-height: 18px;
          display: inline-block;
          padding: 0 8px;
          border-radius: 10px;
          background: #33bbff;
          color: #fff;
        }
        & + .item {
          margin-left: 24px;
        }
      }
    }
    .more {
      &:hover {
        color: #409eff;
      }
    }
  }
  .todo-main-warp {
    height: calc(100% - 32px);
    .todo-list-warp {
      width: 100%;
      height: 100%;
      overflow-y: auto;
      .list-item {
        width: 100%;
        display: flex;
        padding: 12px 0px;
        justify-content: space-between;
        align-items: flex-end;
        .info-warp {
          display: flex;
          .info-icon {
            height: 36px;
            width: 36px;
            line-height: 36px;
            text-align: center;
            border-radius: 6px;
            font-size: 22px;
            font-family: YouSheBiaoTiHei;
          }
          .q-icon {
            background: rgba(90, 204, 120, 0.2);
            color: #5acc78;
          }
          .s-icon {
            background: rgba(255, 170, 74, 0.2);
            color: #ffaa4a;
          }
          .p-icon {
            background: rgba(51, 187, 255, 0.2);
            color: #33bbff;
          }
          .info-text-warp {
            margin-left: 12px;
            .line-1 {
              display: flex;
              align-items: center;
              .info-title {
                height: 22px;
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 500;
                color: #292f3d;
                line-height: 22px;
              }
              .status {
                margin-left: 8px;
                height: 20px;
                padding: 0 8px;
                box-sizing: border-box;
                border-radius: 12px;
                line-height: 20px;
                font-size: 12px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
              }
              .w-status {
                background: #fff1f1;
                color: #e0452d;
              }
              .s-status {
                background: #e1f5e7;
                color: #21a35d;
              }
              .p-status {
                color: #0088cc;
                background: #e0f4ff;
              }
            }
            .line-2 {
              height: 18px;
              font-size: 12px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #6d768f;
              line-height: 18px;
            }
          }
        }
        .time-status {
          height: 100%;
          white-space: nowrap;
          height: 18px;
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #6d788f;
          line-height: 18px;
          margin-right: 5px;
        }
      }
    }
  }
}
.four-news {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .title {
    display: flex;
    height: 52px;
    padding: 0px var(--spacing-8, 16px) 0px var(--spacing-10, 20px);
    justify-content: space-between;
    align-items: center;
    color: var(--text-on-surface-primary, #181b22);

    /* web/cn/heading/heading-02 */
    font-family: 'PingFang SC';
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 24px */
    letter-spacing: 0.5px;
    .more {
      color: var(--text-on-surface-tertiary, #707786);
      /* web/number/medium/helper-01 */
      font-family: 'JetBrains Mono NL';
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 150%; /* 18px */
      display: flex;
      align-items: center;
    }
  }
  .list {
    display: flex;
    padding: var(--spacing-10, 20px);
    flex-direction: column;
    align-items: flex-start;
    flex: 1 0 0;
    align-self: stretch;
    border-radius: 16px;
    border: 1px solid rgba(255, 255, 255, 0.6);
    // background: linear-gradient(
    //   180deg,
    //   rgba(255, 255, 255, 0.24) 0%,
    //   rgba(255, 255, 255, 0.6) 100%
    // );
    background: #fff;
    backdrop-filter: blur(13px);
    overflow: auto;
    gap: 16px;
    .category {
      display: grid;
      grid-template-columns: repeat(3, 31.5%);
      grid-column-gap: 16px;
      grid-row-gap: 16px;
      width: -webkit-fill-available;

      .item {
        display: flex;
        padding: var(--spacing-6, 12px) 14px var(--spacing-6, 12px)
          var(--spacing-6, 12px);
        align-items: center;
        gap: var(--spacing-6, 12px);
        flex: 1 0 0;
        border-radius: 12px;
        border: 1px solid #f9f6f4;
        background: #fff;
        &:hover {
          border: 1px solid rgba(249, 246, 244, 0.6);
          background: linear-gradient(
            180deg,
            rgba(247, 171, 25, 0) 2.19%,
            rgba(242, 119, 19, 0.1) 35.07%,
            rgba(231, 0, 6, 0.1) 98.44%
          );
        }

        .icon {
          display: flex;
          width: var(--button-list-field-medium, 32px);
          height: var(--button-list-field-medium, 32px);
          justify-content: center;
          align-items: center;
          border-radius: 100px;
        }
        .text {
          font-family: D-DIN;
          font-size: 14px;
          font-style: normal;
          font-weight: 700;
          line-height: 20px; /* 142.857% */
          letter-spacing: 0.4px;
          flex: 1;
          align-items: center;
          justify-content: space-between;
          display: flex;
        }
        .num {
          font-family: D-DIN;
          font-size: 14px;
          font-style: normal;
          font-weight: 700;
          line-height: 20px; /* 142.857% */
          letter-spacing: 0.4px;
          -webkit-background-clip: text !important; /*将设置的背景颜色限制在文字中*/
          -webkit-text-fill-color: transparent; /*给文字设置成透明*/
        }
      }
      .active {
        border: 1px solid rgba(249, 246, 244, 0.6);
        background: linear-gradient(
          180deg,
          rgba(247, 171, 25, 0) 2.19%,
          rgba(242, 119, 19, 0.1) 35.07%,
          rgba(231, 0, 6, 0.1) 98.44%
        );
      }
    }
    .content {
      display: flex;
      flex: 1;
      overflow: auto;
      flex-direction: column;
      gap: 16px;
      &-item {
        display: flex;
        padding: var(--spacing-6, 12px) 14px;
        flex-direction: column;
        align-items: flex-start;
        gap: var(--spacing-3, 6px);
        align-self: stretch;
        border-radius: 12px;
        border: 1px solid #f9f6f4;
        background: var(--overall-surface-default, #fff);
      }
    }
  }
}
</style>

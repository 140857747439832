<template>
	<div class="platform-dynamics">
		<article class="main">
			<div class="map-box" v-loading="dynamicLoading">
				<div class="todo-tabs">
					<div class="item">
						<span class="text active">平台动态</span>
					</div>
				</div>
				<section class="todo-main-warp">
					<div class="dynamic-box" v-if="init && dynamicList.length">
						<DynamicList
							class="dynamic-list"
							:list="dynamicList"
							:rankData="rankList"
							:isUpdate="isUpdate"
							@refresh="refresh"
						/>
					</div>
					<el-empty v-else description="暂无数据" :image-size="80"></el-empty>
				</section>
			</div>
		</article>
	</div>
</template>

<script>
import { formRequest } from '@/apis/data/form';
import DynamicList from './dynamicList.vue';
import { mapState } from 'vuex';
import { Empty } from 'element-ui';

export default {
	name: 'PlatformDynamics',
	components: {
		'el-empty': Empty,
		DynamicList
	},
	data() {
		return {
			rankList: [],
			dynamicList: [],
			dynamicLoading: true,
			page: 1,
			size: 20,
			total: 0,
			isUpdate: true, // 是否到底
			init: false,
			defaultAvatar: require('@/assets/images/default-avatar.png')
		};
	},
	computed: {
		...mapState(['targetArchi'])
	},
	watch: {
		targetArchi: {
			handler() {
				this.getDynamicList();
			},
			deep: true,
			immediate: true
		}
	},
	methods: {
		/**
		 * @description: 刷新
		 */
		refresh() {
			this.page += 1;
			this.updateData();
		},
		/**
		 * @desc:下拉刷新数据
		 */
		async updateData() {
			this.dynamicLoading = true;
			const url = 'api/mapi',
				method = 'POST';
			const param = {
				object_uuid: 'object62cbe374ef233',
				view_uuid: 'view62cd1cf39e2f3',
				__method_name__: 'dataList',
				transcode: 0,
				size: this.size,
				page: this.page
			};
			let res = await formRequest(method, url, param);
			if (res.data.code === 200) {
				let dataList = this.dynamicList;
				let list = res.data.data.data;

				if (list && list.length) {
					list.forEach((item) => {
						if (!item.avatar) {
							item.avatar = this.defaultAvatar;
						}
						dataList.push(item);
					});
					this.dynamicList = dataList;
					this.total = dataList.length;
				} else {
					this.page -= 1;
					if (list.length === 0) {
						this.isUpdate = false;
						this.$message.warning(this.$t('notice.index.787958-6'));
					} else {
						this.$message.warning(this.$t('notice.index.787958-6'));
					}
				}
			}
			this.dynamicLoading = false;
		},
		/**
		 * @desc: 获取工程动态数据列表
		 */
		async getDynamicList() {
			this.dynamicLoading = true;
			const url = 'api/mapi',
				method = 'POST';
			const param = {
				object_uuid: 'object62cbe374ef233',
				view_uuid: 'view62cd1cf39e2f3',
				__method_name__: 'dataList',
				transcode: 0,
				size: this.size,
				page: this.page
			};
			let res = await formRequest(method, url, param);
			if (res.data.code === 200) {
				this.dynamicList = res.data.data.data;
				if (this.dynamicList?.length) {
					this.dynamicList.forEach((item) => {
						if (!item.avatar) {
							item.avatar = this.defaultAvatar;
						}
					});
				}

				this.total = res.data.data.total;
			}
			const rankParam = {
				object_uuid: 'object62cbe374ef233',
				view_uuid: 'view62ce9543b87f7',
				__method_name__: 'dataList',
				transcode: 0
			};
			let rankRes = await formRequest(method, url, rankParam);
			if (rankRes.data.code === 200) {
				let arr = rankRes.data.data;
				if (arr.length) {
					arr.sort((a, b) => parseInt(b.nums) - parseInt(a.nums));
					arr.forEach((item) => {
						if (!item.avatar) {
							item.avatar = this.defaultAvatar;
						}
					});
				}
				if (arr.length > 5) {
					this.rankList = arr.slice(0, 5);
				} else {
					this.rankList = arr;
				}
				this.init = true;
			}
			this.dynamicLoading = false;
		}
	}
};
</script>

<style lang="less" scoped>
.platform-dynamics {
	height: 100%;
	width: 100%;
	box-sizing: border-box;
	background: #ffffff;
	border-radius: 8px;
	padding: 16px 20px 20px 20px;
	box-sizing: border-box;
	overflow: hidden;
	.main {
		height: calc(100% - 24px);
		display: flex;
		flex-direction: column;
		.map-box {
			flex: 1;
			height: 100%;
			box-sizing: border-box;
			overflow: hidden;
			margin-top: 8px;
			.todo-tabs {
				// height: 100%;
				display: flex;
				align-items: center;
				.item {
					display: flex;
					align-items: center;
					cursor: pointer;
					.text {
						font-size: 16px;
						font-family: PingFangSC-Medium, PingFang SC;
						font-weight: 600;
						color: #6d768f;
						line-height: 24px;
						letter-spacing: 1px;
						&:hover {
							color: #409eff;
						}
					}
					.active {
						color: #2a2f3d;
					}
					& + .item {
						margin-left: 24px;
					}
				}
			}
			.todo-main-warp {
				padding-top: 15px;
				height: calc(100% - 24px);
				box-sizing: border-box;
				overflow: hidden;
				.dynamic-box {
					height: 100%;
					display: flex;
					flex-direction: column;
					.pagination {
						height: 32px;
						text-align: right;
					}
					.dynamic-list {
						flex: 1;
						height: 100%;
					}
				}
				.image-box {
					height: 100%;
					box-sizing: border-box;
					.img {
						height: 100%;
						width: 100%;
					}
				}
			}
		}
	}
}
</style>

var render = function render(){var _vm=this,_c=_vm._self._c;return (+_vm.systemConfig.theme_type !== 4)?_c('div',{staticClass:"todo-list-warp",on:{"scroll":_vm.scrollEvent}},[_vm._l((_vm.getLocalizedData(_vm.list, [
      'archi_name',
      'object_name',
      'title',
    ])),function(item,index){return _c('div',{key:index,staticClass:"list-item",on:{"click":function($event){$event.stopPropagation();return _vm.handleClick(item)}}},[_c('div',{staticClass:"info-warp"},[_c('div',{class:{ 'before-border': true },style:({
          backgroundColor: _vm.stateList.find((s) => s.value === +item.status)
            ?.backgroundColor,
        })}),_c('div',{staticClass:"info-text-warp"},[_c('div',{staticClass:"line-1"},[_c('div',{staticClass:"line-title"},[_c('div',{staticClass:"info-title"},[_vm._v(" "+_vm._s(item.t_object_name)+" | "+_vm._s(item.t_title)+" ")])]),_c('p',{staticClass:"status",class:_vm.filterStatus(item.status).class,style:({
              backgroundColor: _vm.stateList.find((s) => s.value === +item.status)
                ?.color,
            })},[_vm._v(" "+_vm._s(_vm.stateList.find((s) => s.value === +item.status)?.label)+" ")])]),_c('div',{staticClass:"line-2"},[_c('p',{staticClass:"project-name"},[_vm._v(" "+_vm._s(_vm.$t('todoCom.WarringList.792663-2'))+_vm._s(item.t_archi_name)+" ")])]),_c('div',{staticClass:"line-2"},[_c('p',{staticClass:"project-info"},[_vm._v(" "+_vm._s(_vm.$t('todoCom.index.735398-6'))+" "),_c('img',{staticClass:"avatar",attrs:{"src":item.user_avatar ||
                'https://dwh_api.bimcc.net/uploads/202301/17/1673943165_C8E5tNmVgm.png',"alt":""}}),_vm._v(_vm._s(item.user_name)+" ")]),_c('p',{class:{
              'time-status': true,
              [_vm.filterTime(item.endDate).class]: true,
            }},[_vm._v(" "+_vm._s(_vm.filterTime(item.endDate).time)+" ")])])])])])}),(_vm.list.length === 0)?_c('Empty',{attrs:{"description":_vm.$t('todoCom.TodoList.785059-1'),"image-size":100}}):_vm._e()],2):_c('div',{staticClass:"four-todo"},_vm._l((_vm.list),function(item,index){return _c('div',{key:index,staticClass:"item",on:{"click":function($event){$event.stopPropagation();return _vm.handleClick(item)}}},[_c('div',{staticClass:"top"},[_c('div',{staticClass:"left"},[_c('div',{staticClass:"name"},[_vm._v(_vm._s(item.object_name))]),_c('div',{staticClass:"status",class:_vm.filterStatus(item.status).class},[_vm._v(" "+_vm._s(_vm.filterStatus(item.status).label)+" ")])]),_c('div',{staticClass:"right"},[_c('img',{attrs:{"src":"https://openim-1309784708.cos.ap-shanghai.myqcloud.com/af3ccbee493ceb8b99120f0946980aa4.png","alt":""}}),_vm._v(" "+_vm._s(item.from_user_name)+">"+_vm._s(_vm.$t('todoCom.TodoList.me'))+" ")])]),_c('div',{staticClass:"bottom"},[_c('div',{staticClass:"project"},[_vm._v(" "+_vm._s(_vm.$t('todoCom.WarringList.792663-2'))+_vm._s(item.archi_name)+" ")]),_c('div',{staticClass:"date"},[_vm._v(_vm._s(item.startDate))])])])}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }
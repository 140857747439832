<!--
 * @Author: zqy
 * @Date: 2022-06-17 18:32:04
 * @LastEditTime: 2024-11-06 19:27:49
 * @Description: 
 * @LastEditors: 冉桂精 156189868@qq.com
-->
<template>
	<div style="width: 100%; height: 100%; position: relative">
		<div v-if="systemConfig.theme_type != 4" class="notice" v-loading="loading">
			<header>
				<div class="left">
					<div @click="readStatus = 'unread'" style="display: flex; align-items: center">
						<h4 class="title" style="color: #2a2f3d">{{ $t('index.GridComponent.540553-6') }}</h4>
						<div v-show="unreadAllList.length" class="number">
							<p>{{ unreadAllList.length }}</p>
						</div>
					</div>
					<!-- <h4
          @click="readStatus = 'read'"
          class="title"
          style="margin-left: 24px"
          :style="readStatus === 'read' ? 'color: #2A2F3D;' : 'color: #6D768F;'"
        >
          已读
        </h4> -->
				</div>
				<div class="right">
					<i class="iconfont iconxiangyoujiantou2" @click="goNotice"></i>
				</div>
			</header>

			<ul class="notice-list">
				<template v-if="showNoticeList && showNoticeList.length">
					<li v-for="(item, index) in showNoticeList" :key="index" class="list-item" @click="openDetail(item)">
						<div class="left">
							<!-- <el-badge is-dot class="item" v-if="+item.is_read !== 1">
								<div v-if="item.announcement_source === 1" class="badge" :style="getColor(item.notice_type)">
									{{ item.notice_type }}
								</div>
								<div v-else class="badge" :style="getColor('通知')">
									{{ $t('systemNotice.systemNoticePop.286370-5') }}
								</div>
							</el-badge>
							<div v-else>
								<div v-if="item.announcement_source === 1" class="badge" :style="getColor(item.notice_type)">
									{{ item.notice_type }}
								</div>
								<div v-else class="badge" :style="getColor('通知')">
									{{ $t('systemNotice.systemNoticePop.286370-5') }}
								</div>
							</div> -->
							<el-badge is-dot class="item" v-if="+item.is_read !== 1">
								<div class="badge" :class="getClass(item)">
									<img
										:src="
											item.notice_type == '通知'
												? 'https://openim-1309784708.cos.ap-shanghai.myqcloud.com/8afa61c8ca51883dfc91bd1a42dcbb6b.png'
												: 'https://openim-1309784708.cos.ap-shanghai.myqcloud.com/77c6eb331b0d2dae5edbb12e6d8d7cd1.png'
										"
									/>
								</div>
							</el-badge>
							<div class="badge" v-else :class="getClass(item)">
								<img
									:src="
										item.notice_type == '通知'
											? 'https://openim-1309784708.cos.ap-shanghai.myqcloud.com/8afa61c8ca51883dfc91bd1a42dcbb6b.png'
											: 'https://openim-1309784708.cos.ap-shanghai.myqcloud.com/77c6eb331b0d2dae5edbb12e6d8d7cd1.png'
									"
								/>
							</div>
						</div>
						<div class="item-content">
							<div class="content-title">
								<div class="content-div">
									{{ item.title }}
									<svg
										class="to-top-bnt"
										v-if="item.is_to_top"
										xmlns="http://www.w3.org/2000/svg"
										width="14"
										height="14"
										viewBox="0 0 14 14"
										fill="none"
									>
										<path
											fill-rule="evenodd"
											clip-rule="evenodd"
											d="M1.2688 12.1152L3.74367 7.78414L1.47342 5.51389C1.30257 5.34304 1.30257 5.06603 1.47342 4.89517C1.56263 4.80597 1.68601 4.75961 1.81189 4.768L5.29047 4.99991L7.14662 3.14375L7.35546 1.26422C7.38214 1.02408 7.59845 0.851031 7.8386 0.877714C7.93734 0.888685 8.0294 0.932933 8.09964 1.00318L12.9995 5.90304C13.1704 6.0739 13.1704 6.35091 12.9995 6.52176C12.9293 6.59201 12.8372 6.63626 12.7385 6.64723L10.8589 6.85606L9.00278 8.71222L9.23468 12.1908C9.25076 12.4319 9.06835 12.6404 8.82726 12.6564C8.70138 12.6648 8.578 12.6185 8.48879 12.5293L6.21855 10.259L1.88752 12.7339L1.2688 12.1152Z"
											fill="#BFC7D8"
										/>
									</svg>
									<!-- <Marquee>{{ item.title }}</Marquee> -->
									<!-- <div></div> -->
								</div>
							</div>
							<div class="content-main">
								<div class="main-text" v-html="item.text"></div>
								<div class="main-time">
									{{ getFormatDate(item.created_at) }}
								</div>
							</div>
						</div>
					</li>
				</template>
				<el-empty v-else></el-empty>
			</ul>
			<!-- <DetailDailog
      v-if="detailShow"
      form_uuid="form629084dae1b4a"
      :data_id="data_id"
      title="详情"
    ></DetailDailog> -->
		</div>
		<div v-else class="four-news" v-loading="loading">
			<div class="title">
				{{ $t('index.GridComponent.540553-6')
				}}<span class="more" @click="goNotice"
					>{{ $t('news.Index.953365-6') }} <i class="iconfont iconxiangyoujiantou2"></i
				></span>
			</div>
			<div class="list" v-if="showNoticeList && showNoticeList.length" @scroll="scrollEvent">
				<div class="item" v-for="(item, index) in showNoticeList" :key="index" @click="openDetail(item)">
					<div class="point" v-if="+item.is_read === 0"></div>
					<div class="desc">
						<div :class="{ icon: true, post: item.notice_type === '公告' }">
							<img
								:src="
									item.notice_type === '通知'
										? 'https://openim-1309784708.cos.ap-shanghai.myqcloud.com/8eaf7be1e7fe4831622d43a91816a5f8.png'
										: 'https://openim-1309784708.cos.ap-shanghai.myqcloud.com/94877e9154286e3c2066379dae298fb8.png'
								"
								alt=""
							/>
							{{ item.notice_type }}
						</div>
						<div class="text">
							{{ item.title }}
						</div>
					</div>
					<div class="date">{{ $t('systemNotice.systemNoticePop.286370-27') }}{{ item.created_at }}</div>
				</div>
			</div>
			<el-empty v-else></el-empty>
		</div>
		<el-dialog :visible.sync="isSysDetailPop" append-to-body>
			<SystemDetailNotifyPop v-if="isSysDetailPop" :item="SysDetailItemData" @close-pop="isSysDetailPop = false" />
		</el-dialog>
	</div>
</template>
<script>
import { Badge, Empty } from 'element-ui';
import { mapState } from 'vuex';
import { dataInterface } from '@/apis/data';
import SystemDetailNotifyPop from '@/views/todo/components/rightCont/systemNotice/systemDetailNotifyPop.vue';
// import Marquee from '@/views/todo/components/rightCont/marquee.vue'
// import DetailDailog from '@/components/global/Dailog'
const BLUETYPES = ['公告', '公报', '决议', '决定'];
// const PURPLETYPES = ['通知','通告','通报','意见','命令']
export default {
	components: {
		'el-badge': Badge,
		'el-empty': Empty,
		SystemDetailNotifyPop
		// Marquee
		// DetailDailog
	},
	props: {
		archi_id: {
			type: [String, Number],
			default: 0
		}
	},
	data() {
		return {
			noticeList: [],
			data_id: '',
			detailShow: false,
			dailogTitle: '',
			readStatus: 'unread', //未读  read
			unreadList: [],
			unreadAllList: 0,
			readList: [],
			loading: false,
			// 分页数据
			page: 1,
			size: 10,
			total: 0,
			isUpdate: true, // 是否到底
			isSysDetailPop: false,
			SysDetailItemData: null
		};
	},
	computed: {
		showNoticeList() {
			// if (this.readStatus === "unread") {
			//   return this.unreadList;
			// } else {
			return this.readList;
			// }
		},
		...mapState(['targetArchi', 'systemConfig'])
	},
	watch: {
		targetArchi: {
			handler() {
				this.getNoticeList();
				this.getNoticeLength();
			},
			deep: true,
			immediate: true
		}
	},
	methods: {
		getReadStatus(user) {
			const userId = this.$GetUserInfo('id');
			if (user && Array.isArray(user) && user.length) {
				let flag = false;
				for (let i = 0; i < user.length; i++) {
					if (+user[i].yong_id === +userId && user[i].is_read) {
						//已读
						flag = true;
					}
				}
				if (flag) {
					return true;
				} else {
					return false;
				}
			} else {
				return false;
			}
		},
		getColor(item) {
			if (item === '公告' || item === '公报') {
				return 'background:linear-gradient(180deg, #FFD747 0%, #FCA832 100%)';
			} else if (item === '意见') {
				return 'background:linear-gradient(180deg, #62F5C4 0%, #4BD1C4 100%)';
			} else if (item === '命令') {
				return 'background:#0FB7FA';
			} else if (item === '决定' || item === '决议') {
				return 'background:linear-gradient(180deg, #46C7FA 0%, #0F94FA 100%)';
			} else {
				return 'background:linear-gradient(180deg, #6FDCFC 0%, #55BEF0 100%)';
			}
		},
		openDetail(item) {
			console.log(1111);

			this.SysDetailItemData = item;
			this.isSysDetailPop = true;
		},
		getClass(item) {
			if (BLUETYPES.indexOf(item.notice_type) !== -1) {
				return 'blue-card';
			} else {
				return 'purple-card';
			}
		},
		getFormatDate(created_at) {
			const date = new Date(created_at);
			const year = date.getFullYear();
			const month = (date.getMonth() + 1).toString();
			const day = date.getDate().toString();
			if (created_at) {
				return `${year}/${month}/${day}`;
			} else {
				return '-';
			}
		},
		getNoticeList() {
			this.loading = true;
			// const url = "api/mapi",
			//   method = "POST";
			// const param = {
			//   object_uuid: "object62907b0f7a148",
			//   view_uuid: "view62ac53fbb5b90",
			//   __method_name__: "dataList",
			//   transcode: 0,
			//   size: this.size,
			//   page: this.page,
			// };
			// if (this.archi_id) {
			//   param.archi_id = this.archi_id
			// }
			dataInterface({}, 'api/graph/1287').then((res) => {
				this.loading = false;
				if (res && +res.code == 200) {
					let data = res.data.data || [];
					this.noticeList = data;
					let unreadList = this.unreadList;

					if (data && data.length) {
						data.forEach((item) => {
							let flag = +item.is_read == 1 ? true : false;
							if (!flag) {
								unreadList.push(item);
							}
						});
						this.unreadList = unreadList;
					} else {
						this.page -= 1;
						if (data.length === 0) {
							this.isUpdate = false;
						}
					}
				}
			});
			// formRequest(method, url, param)
			//   .then((res) => {
			//     this.loading = false;
			//     if (res && +res.data.code == 200) {
			//       let data = res.data.data.data || [];
			//       this.noticeList = data;
			//       let unreadList = this.unreadList;

			//       if (data && data.length) {
			//         data.forEach((item) => {
			//           let flag = +item.is_read == 1 ? true : false;
			//           if (!flag) {
			//             unreadList.push(item);
			//           }
			//         });
			//         this.unreadList = unreadList;
			//       } else {
			//         this.page -= 1;
			//         if (data.length === 0) {
			//           this.isUpdate = false;
			//         }
			//       }
			//     }
			//   })
			//   .catch(() => (this.loading = false));
		},
		/**
		 * @desc: 获取通知总数
		 * @return {*}
		 */
		getNoticeLength() {
			// if (this.archi_id) {
			//   param.archi_id = this.archi_id
			// }
			dataInterface({}, 'api/graph/1287').then((res) => {
				this.loading = false;
				console.log(res);

				if (res && +res.data.code == 200) {
					let data = res.data.data || [];
					const isTop = data.filter((item) => item.is_to_top);
					const notTop = data.filter((item) => !item.is_to_top);
					const notRead = notTop.filter((item) => +item.is_read === 0);
					const isRead = notTop.filter((item) => +item.is_read === 1);
					const isSys = isRead.filter((item) => +item.announcement_source === 2);
					const notSys = isRead.filter((item) => +item.announcement_source !== 2);
					this.readList = [...isTop, ...notRead, ...isSys, ...notSys];
					this.unreadAllList = 0;
					// this.readList = data;
					let arr = [];
					if (data && data.length) {
						data.forEach((item) => {
							let flag = +item.is_read == 1 ? true : false;
							if (flag) {
								// this.readList.push(item);
							} else {
								arr.push(item);
							}
						});
					}
					this.unreadAllList = arr;
				}
			});
		},

		goNotice() {
			// this.$store.commit('setShowAppCenter', true);
			this.$router.push({
				path: 'notice-notice',
				query: {
					pageUUID: 'page62908b64de319'
				}
			});
		},
		/**
		 * @desc: 滚动加载
		 * @param {Object} e
		 */
		scrollEvent(e) {
			if (
				(e.target.offsetHeight + e.target.scrollTop - e.target.scrollHeight === 0 &&
					+this.systemConfig.theme_type !== 4) ||
				(e.target.offsetHeight + e.target.scrollTop - e.target.scrollHeight === 2 &&
					+this.systemConfig.theme_type === 4)
			) {
				if (this.isUpdate) {
					this.page += 1;
					this.getNoticeList();
				} else {
					this.$message.warning(this.$t('todoCom.index.735398-3'));
				}
			}
		}
	},
	mounted() {},
	provide() {
		return {
			toUpdate: (p1, p2) => {
				this.getNoticeLength();
				console.log(p1, p2);
			}
		};
	}
};
</script>
<style lang="less" scoped>
.blue-card {
	background: #ddf1fb;
	color: #31b4f5;
}
.purple-card {
	background: #f0eaff;
	color: #9c86ff;
}
.notice {
	width: 100%;
	height: 100%;
	background: #fff;
	border-radius: 8px;
	padding: 16px 20px;
	box-sizing: border-box;
	// overflow-y: auto;
	display: flex;
	flex-direction: column;
	header {
		width: 100%;
		height: 30px;
		display: flex;
		justify-content: space-between;
		padding: 4px 0;
		.left {
			font-size: 16px;
			font-family: PingFangSC-Medium, PingFang SC;
			font-weight: 500;
			color: #6d768f;
			display: flex;
			align-items: center;
			.number {
				margin-left: 8px;
				height: 18px;
				line-height: 18px;
				display: inline-block;
				padding: 0 8px;
				border-radius: 10px;
				background: #33bbff;
				color: #fff;
				font-size: 14px;
			}
		}
		.right {
			i {
				font-size: 20px;
			}
		}
	}
	.notice-list {
		width: 100%;
		flex: 1;
		overflow: hidden;
		overflow-y: auto;
		.list-item {
			width: 100%;
			display: flex;
			justify-content: space-between;
			height: 64px;
			overflow: hidden;
			gap: 12px;
			.left {
				//display: flex;
				//align-items: center;
				width: 24px;
				.badge {
					//display: flex;
					//width: 36px;
					//height: 36px;
					//flex-direction: column;
					//justify-content: center;
					//align-items: center;
					//border-radius: var(--Radio-999, 999px);
					//color: rgba(255, 255, 255, 0.9);
					//text-align: right;
					//text-shadow: 0px 0.45px 0.9px rgba(0, 0, 0, 0.1);
					//font-family: 'MiSans VF';
					//font-size: 13px;
					//font-style: normal;
					//font-weight: 450;
					//line-height: 20px;

					display: flex;
					width: var(--button-list-field-small, 24px);
					height: var(--button-list-field-small, 24px);
					justify-content: center;
					align-items: center;
					border-radius: var(--radius-3, 6px);
					background: rgba(255, 255, 255, 0.1);
					margin-top: 12px;
					img {
						width: 16px;
						height: 16px;
					}
				}
				.title {
					font-size: 14px;
					font-family: PingFangSC-Regular, PingFang SC;
					font-weight: 400;
					color: #2a2f3d;
					padding-left: 8px;
					overflow: hidden;
					white-space: nowrap;
					text-overflow: ellipsis;
				}
			}
			.item-content {
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: flex-start;
				flex: 1 0 0;
				align-self: stretch;
				overflow: hidden;
				.content-title {
					display: flex;
					align-items: center;
					gap: 6px;
					align-self: stretch;

					.to-top-bnt {
						padding: 3px;
						border-radius: 3px;
					}

					img {
						display: flex;
						width: 22px;
						height: 22px;
						justify-content: center;
						align-items: center;
						gap: 8px;
					}

					.content-div {
						width: 100%;
						display: -webkit-box;
						-webkit-box-orient: vertical;
						-webkit-line-clamp: 1;
						flex: 1 0 0;
						overflow: hidden;
						color: var(--text-on-surface-primary, #181b22);
						text-overflow: ellipsis;
						font-family: 'MiSans VF';
						font-size: 14px;
						font-style: normal;
						font-weight: 330;
						line-height: 22px;
						display: flex;
						justify-content: space-between;
						padding-right: 20px;
						box-sizing: border-box;
						// width: 178px;
						/* 157.143% */
					}
				}

				.content-main {
					display: flex;
					align-items: center;
					gap: var(--spacing-12, 24px);
					align-self: stretch;
					width: 100%;
					overflow: hidden;
					.main-text {
						display: -webkit-box;
						-webkit-box-orient: vertical;
						-webkit-line-clamp: 1;
						flex: 1 0 0;
						overflow: hidden;
						color: var(--text-on-surface-tertiary, #707786);
						text-overflow: ellipsis;
						font-family: 'MiSans VF';
						font-size: 12px;
						font-style: normal;
						font-weight: 330;
						line-height: 18px;
						width: 115px;
						overflow: hidden;
						text-overflow: ellipsis;
						max-width: 80%;
						overflow: hidden;
						// white-space: nowrap;
						/* 150% */
					}

					.main-time {
						color: var(--text-on-surface-placeholder, #a4acbd);
						text-align: right;
						font-family: 'MiSans VF';
						font-size: 12px;
						font-style: normal;
						font-weight: 330;
						line-height: 18px;
						/* 150% */
					}
				}
			}
		}
	}
}
.four-news {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	.title {
		display: flex;
		height: 52px;
		padding: 0px var(--spacing-8, 16px) 0px var(--spacing-10, 20px);
		justify-content: space-between;
		align-items: center;
		color: var(--text-on-surface-primary, #181b22);

		/* web/cn/heading/heading-02 */
		font-family: 'PingFang SC';
		font-size: 16px;
		font-style: normal;
		font-weight: 500;
		line-height: 150%; /* 24px */
		letter-spacing: 0.5px;
		.more {
			color: var(--text-on-surface-tertiary, #707786);
			/* web/number/medium/helper-01 */
			font-family: 'JetBrains Mono NL';
			font-size: 12px;
			font-style: normal;
			font-weight: 500;
			line-height: 150%; /* 18px */
			display: flex;
			align-items: center;
		}
	}
	.list {
		display: flex;
		padding: var(--spacing-10, 20px);
		flex-direction: column;
		align-items: flex-start;
		flex: 1 0 0;
		align-self: stretch;
		border-radius: 16px;
		border: 1px solid rgba(255, 255, 255, 0.6);
		// background: linear-gradient(
		//   180deg,
		//   rgba(255, 255, 255, 0.24) 0%,
		//   rgba(255, 255, 255, 0.6) 100%
		// );
		background: #fff;
		backdrop-filter: blur(13px);
		overflow: auto;
		gap: 12px;
		.item {
			display: flex;
			padding: var(--spacing-6, 12px) var(--spacing-8, 16px);
			flex-direction: column;
			align-items: flex-start;
			gap: var(--spacing-2, 4px);
			align-self: stretch;
			border-radius: 12px;
			border: 1px solid #f9f6f4;
			background: var(--overall-surface-default, #fff);
			position: relative;
			&:hover {
				background: linear-gradient(
					180deg,
					rgba(247, 171, 25, 0) 2.19%,
					rgba(242, 119, 19, 0.1) 52.39%,
					rgba(231, 0, 6, 0.1) 98.44%
				);
			}
			.point {
				width: 4px;
				height: 16px;
				position: absolute;
				left: 1px;
				top: 15px;
				border-radius: 0px var(--radius-3, 6px) var(--radius-3, 6px) 0px;
				background: linear-gradient(180deg, #e80a08 0%, #f7a919 100%);
			}
			.desc {
				display: flex;
				align-items: center;
				width: -webkit-fill-available;
				gap: 8px;
				.icon {
					display: flex;
					height: 22px;
					padding: 0px var(--spacing-3, 6px);
					align-items: center;
					gap: var(--spacing-2, 4px);
					border-radius: var(--radius-3, 6px);
					background: rgba(246, 125, 0, 0.1);
					color: #f67d00;

					/* web/cn/label/label-02 */
					font-family: 'PingFang SC';
					font-size: 12px;
					font-style: normal;
					font-weight: 500;
					line-height: 150%; /* 18px */
					letter-spacing: 0.4px;
				}
				.post {
					background: rgba(236, 49, 13, 0.1);
					color: #ec310d;
				}
				.text {
					flex: 1;
					white-space: nowrap;
					text-overflow: ellipsis;
					overflow: hidden;
				}
			}
			.date {
				color: var(--text-on-surface-tertiary, #707786);

				/* web/cn/helper/helper-02 */
				font-family: 'PingFang SC';
				font-size: 12px;
				font-style: normal;
				font-weight: 400;
				line-height: 150%; /* 18px */
				letter-spacing: 0.4px;
			}
		}
	}
}
</style>

<!--
 * @Author: zx
 * @Date: 2022-06-17 19:37:09
 * @LastEditTime: 2024-11-06 17:26:21
 * @Description: 
 * @LastEditors: 冉桂精 156189868@qq.com
-->
<template>
	<div class="news" v-if="+systemConfig.theme_type !== 4" v-loading="loading">
		<header>
			<div class="left">
				<h4 class="title">{{ $t('index.GridComponent.540553-8') }}</h4>
			</div>
			<div class="right">
				<i class="iconfont iconxiangyoujiantou2" @click="goNewsRelease"></i>
			</div>
		</header>
		<div class="content" v-if="allNewsList && allNewsList.length">
			<div class="image">
				<el-carousel
					v-if="imageNewsList.length >= 3"
					:interval="300000"
					type="card"
					style="width: 100%; height: 100%; overflow: hidden"
					height="90%"
					@change="changeCardIndex"
				>
					<el-carousel-item v-for="(item, index) in imageNewsList" :key="'img' + index">
						<div
							class="image-comtainer"
							@click="detailClick(item)"
							:style="{ padding: cardIndex === index ? '0 10px' : '' }"
						>
							<img
								v-if="showImg(item)"
								fit="fit"
								:src="item.page_cover[0].url"
								style="height: 100%; width: 100%"
								:alt="$t('news.Index.953365-0')"
							/>
							<div v-else class="img-set">
								<span>{{ $t('news.Index.953365-1') }}</span>
							</div>
						</div>
					</el-carousel-item>
				</el-carousel>
				<el-carousel
					v-if="imageNewsList.length === 1"
					indicator-position="outside"
					style="width: 100%; height: 100%; overflow: hidden"
					height="90%"
				>
					<el-carousel-item v-for="(item, index) in imageNewsList" :key="'img' + index">
						<div class="image-comtainer" @click="detailClick(item)">
							<img
								v-if="showImg(item)"
								:src="item.page_cover[0].url"
								style="height: 100%; width: 100%"
								:alt="$t('news.Index.953365-0')"
							/>
							<div v-else class="img-set">
								<span>{{ $t('news.Index.953365-1') }}</span>
							</div>
						</div>
					</el-carousel-item>
				</el-carousel>
				<div class="image-text">
					{{ allNewsList[cardIndex].caption }}
				</div>
			</div>
			<div class="text">
				<template>
					<div class="text-line" v-for="(item, index) in newsList" :key="'key' + index" @click="detailClick(item)">
						<div class="date-text">{{ getData(item) }}</div>
						<div class="text">{{ item.caption }}</div>
					</div>
				</template>
			</div>
		</div>
		<el-empty v-else :description="$t('news.Index.953365-2')"></el-empty>
		<el-dialog
			:title="$t('news.Index.953365-3')"
			:visible.sync="detailVisible"
			modal-append-to-body
			append-to-body
			:close-on-click-modal="false"
			:close-on-press-escape="false"
			:destroy-on-close="true"
			:style="fullScreenVisible ? 'width: 100%;height:100%' : ''"
			:width="fullScreenVisible ? '100vh' : '640'"
			:fullscreen="fullScreenVisible"
		>
			<header class="model-header" slot="title">
				<h3>{{ $t('news.Index.953365-4') }}</h3>
				<i v-if="!fullScreenVisible" class="iconfont icondaping-quanping" @click="changeVisible(true)"></i>
				<i v-if="fullScreenVisible" class="iconfont iconbim_Xzhou" @click="changeVisible(false)"></i>
			</header>
			<div
				class="modify-content"
				:style="
					!fullScreenVisible
						? 'max-height: 700px;overflow-y: auto;padding-right: 8px;'
						: 'max-height: 100%;width: 640px'
				"
			>
				<div class="detail-title">{{ detailInfo.caption }}</div>
				<div class="time-and-name">
					<span>{{ getDate(detailInfo) }}</span>
					<span>{{ $t('news.Index.953365-5') }}{{ detailInfo.unit }}</span>
					<span>{{ getPrograma(detailInfo.programa) }}</span>
					<span>{{ getUser(detailInfo) }}</span>
				</div>
				<div class="text" v-html="detailInfo.text"></div>
			</div>
		</el-dialog>
	</div>
	<div class="four-news" v-else v-loading="loading">
		<div class="title">
			{{ $t('index.GridComponent.540553-8')
			}}<span class="more" @click="goNewsRelease"
				>{{ $t('news.Index.953365-6') }} <i class="iconfont iconxiangyoujiantou2"></i
			></span>
		</div>
		<div class="list" v-if="allNewsList && allNewsList.length">
			<div class="image">
				<el-carousel
					v-if="imageNewsList.length >= 3"
					:interval="300000"
					type="card"
					style="width: 100%; height: 100%; overflow: hidden"
					height="90%"
					@change="changeCardIndex"
				>
					<el-carousel-item v-for="(item, index) in imageNewsList" :key="'img' + index">
						<div
							class="image-comtainer"
							@click="detailClick(item)"
							:style="{ padding: cardIndex === index ? '0 10px' : '' }"
						>
							<img
								v-if="showImg(item)"
								fit="fit"
								:src="item.page_cover[0].url"
								style="height: 100%; width: 100%"
								:alt="$t('news.Index.953365-0')"
							/>
							<div v-else class="img-set">
								<span>{{ $t('news.Index.953365-1') }}</span>
							</div>
						</div>
					</el-carousel-item>
				</el-carousel>
				<el-carousel
					v-if="imageNewsList.length === 1"
					indicator-position="outside"
					style="width: 100%; height: 100%; overflow: hidden"
					height="90%"
				>
					<el-carousel-item v-for="(item, index) in imageNewsList" :key="'img' + index">
						<div class="image-comtainer" @click="detailClick(item)">
							<img
								v-if="showImg(item)"
								:src="item.page_cover[0].url"
								style="height: 100%; width: 100%"
								:alt="$t('news.Index.953365-0')"
							/>
							<div v-else class="img-set">
								<span>{{ $t('news.Index.953365-1') }}</span>
							</div>
						</div>
					</el-carousel-item>
				</el-carousel>
				<div class="image-text">
					{{ allNewsList[cardIndex].caption }}
				</div>
			</div>
			<div class="data">
				<div class="item" @click="detailClick(item)" v-for="(item, index) in newsList" :key="index">
					<div class="num">{{ index + 1 }}</div>
					<div class="text">{{ item.caption }}</div>
					<div class="date">{{ item.publish_date }}</div>
				</div>
			</div>
		</div>
		<el-empty v-else :description="$t('news.Index.953365-2')"></el-empty>
		<el-dialog
			:title="$t('news.Index.953365-3')"
			:visible.sync="detailVisible"
			modal-append-to-body
			append-to-body
			:close-on-click-modal="false"
			:close-on-press-escape="false"
			:destroy-on-close="true"
			:style="fullScreenVisible ? 'width: 100%;height:100%' : ''"
			:width="fullScreenVisible ? '100vh' : '640'"
			:fullscreen="fullScreenVisible"
		>
			<header class="model-header" slot="title">
				<h3>{{ $t('news.Index.953365-7') }}</h3>
				<i v-if="!fullScreenVisible" class="iconfont icondaping-quanping" @click="changeVisible(true)"></i>
				<i v-if="fullScreenVisible" class="iconfont iconbim_Xzhou" @click="changeVisible(false)"></i>
			</header>
			<div
				class="modify-content"
				:style="
					!fullScreenVisible
						? 'max-height: 700px;overflow-y: auto;padding-right: 8px;'
						: 'max-height: 100%;width: 640px'
				"
			>
				<div class="detail-title">{{ detailInfo.caption }}</div>
				<div class="time-and-name">
					<span>{{ getDate(detailInfo) }}</span>
					<span>{{ $t('news.Index.953365-5') }}{{ detailInfo.unit }}</span>
					<span>{{ getPrograma(detailInfo.programa) }}</span>
					<span>{{ getUser(detailInfo) }}</span>
				</div>
				<div class="text" v-html="detailInfo.text"></div>
			</div>
		</el-dialog>
	</div>
</template>
<script>
import { formRequest } from '@/apis/data/form';
import { Carousel, CarouselItem, Empty } from 'element-ui';
import { mapState } from 'vuex';
// import fileUpload from '@/components/fileUpload/newFiles/index'

export default {
	components: {
		'el-carousel': Carousel,
		'el-carousel-item': CarouselItem,
		'el-empty': Empty
		// fileUpload
	},
	props: {
		// archiID: {
		//   type: [String, Number],
		//   default: ''
		// }
	},
	data() {
		return {
			allNewsList: [],
			imageNewsList: [],
			newsList: [],
			detailInfo: {},
			detailVisible: false,
			fullScreenVisible: false,
			loading: false,
			cardIndex: 0
		};
	},
	computed: {
		...mapState(['targetArchi', 'systemConfig'])
	},
	watch: {
		targetArchi: {
			handler() {
				this.getNewsList();
			},
			deep: true,
			immediate: true
		}
	},
	methods: {
		getNewsList() {
			this.loading = true;
			const url = 'api/mapi',
				method = 'POST';
			const param = {
				object_uuid: 'object628eed17e8ef3',
				view_uuid: 'view62fccd28ad5bc',
				__method_name__: 'dataList',
				transcode: 0
			};
			if (this.archiId) {
				param.archi_id = this.archiId;
			}
			formRequest(method, url, param)
				.then((res) => {
					this.loading = false;
					if (res && +res.data.code == 200) {
						let data = res.data.data;
						this.allNewsList = data;
						if (this.allNewsList && this.allNewsList.length >= 3) {
							this.imageNewsList = this.allNewsList.slice(0, 3);
							this.newsList = this.allNewsList;
						} else if (this.allNewsList && this.allNewsList.length && this.allNewsList.length < 3) {
							this.imageNewsList = this.allNewsList.slice(0, 1);
							this.newsList = this.allNewsList;
						} else if (!this.allNewsList || this.allNewsList.length === 0) {
							this.imageNewsList = [];
							this.newsList = [];
						}
					}
				})
				.catch(() => (this.loading = false));
		},
		getData(data) {
			if (data.publish_date.split('-').length === 3) {
				const mounth = data.publish_date.split('-')[1];
				const day = data.publish_date.split('-')[2].split(' ')[0];
				// console.log(mounth+ '-' + day, 'data')
				return mounth + '-' + day;
			} else {
				return '-';
			}
		},
		showImg(data) {
			// console.log(data)
			if (
				data.page_cover &&
				data.page_cover.length &&
				data.page_cover[0].url &&
				(data.page_cover[0].url.indexOf('jpg') !== -1 ||
					data.page_cover[0].url.indexOf('png') !== -1 ||
					data.page_cover[0].url.indexOf('jpeg') !== -1)
			) {
				return true;
			}
			return false;
		},
		detailClick(data) {
			const url = 'api/mapi',
				method = 'POST';
			const param = {
				object_uuid: 'object628eed17e8ef3',
				view_uuid: 'view62ac6473245e0',
				__method_name__: 'dataInfo',
				data_id: data.id,
				transcode: 0
			};
			this.loading = true;
			formRequest(method, url, param).then((res) => {
				this.loading = false;
				this.detailInfo = res.data.data;
				this.detailVisible = true;
			});
		},
		getUser(data) {
			let str = '';
			if (data && Array.isArray(data.writer) && data.writer.length) {
				data.forEach((element) => {
					if (!str) {
						str = element.user_name;
					} else {
						str += ',' + element.user_name;
					}
				});
			} else if (data && data.writer && typeof data.writer === 'string') {
				str = data.writer;
			} else {
				str = '-';
			}
			return str;
		},
		getDate(detailInfo) {
			return detailInfo && detailInfo.publish_date ? detailInfo.publish_date.substring(0, 10) : '-';
		},
		getGenre(num) {
			const arr = [
				this.$t('news.Index.953365-8'),
				this.$t('news.Index.953365-9'),
				this.$t('news.Index.953365-10'),
				this.$t('news.Index.953365-11'),
				this.$t('news.Index.953365-12'),
				this.$t('news.Index.953365-13'),
				this.$t('news.Index.953365-14'),
				this.$t('news.Index.953365-15')
			];
			if (num) {
				return arr[num - 1];
			} else {
				return '-';
			}
		},
		getPrograma(num) {
			const arr = [
				this.$t('news.Index.953365-16'),
				this.$t('news.Index.953365-17'),
				this.$t('news.Index.953365-18'),
				this.$t('news.Index.953365-19'),
				this.$t('news.Index.953365-20'),
				this.$t('news.Index.953365-21'),
				this.$t('news.Index.953365-22'),
				this.$t('news.Index.953365-23'),
				this.$t('news.Index.953365-24'),
				this.$t('news.Index.953365-25'),
				this.$t('news.Index.953365-26'),
				this.$t('news.Index.953365-27')
			];
			if (num) {
				return arr[num - 1];
			} else {
				return '-';
			}
		},
		changeVisible(boo) {
			this.fullScreenVisible = boo;
		},
		goNewsRelease() {
			// this.$store.commit('setShowAppCenter', true);
			this.$router.push({
				path: 'press-release',
				query: {
					pageUUID: 'page628f2ce3846b2'
				}
			});
		},
		changeCardIndex(data) {
			this.cardIndex = data;
		}
	},
	mounted() {
		this.getNewsList();
	}
};
</script>
<style lang="less" scoped>
.news {
	width: 100%;
	height: 100%;
	background: #fff;
	border-radius: 8px;
	padding: 16px 20px;
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	header {
		display: flex;
		justify-content: space-between;
		.left {
			font-size: 16px;
			font-family: PingFangSC-Medium, PingFang SC;
			font-weight: 500;
			color: #2a2f3d;
			display: flex;
			align-items: center;
		}
		.right {
			i {
				font-size: 20px;
			}
		}
	}
	.content {
		flex: 1;
		overflow: hidden;
		display: flex;
		flex-direction: column;
		margin-top: 10px;
		.image {
			width: 100%;
			height: 70%;
			position: relative;
			.image-comtainer {
				width: 100%;
				height: 100%;
				position: relative;
				overflow: hidden;
				background-color: #fff;
				box-sizing: border-box;
				img {
					width: 100%;
					height: 100%;
				}
				.img-set {
					width: 100%;
					height: 100%;
					display: flex;
					justify-content: center;
					align-items: center;
					span {
						color: #999;
					}
				}
			}
			.image-text {
				width: 100%;
				height: 22px;
				line-height: 22px;
				color: #fff;
				font-size: 14px;
				border-radius: 0 0 6px 6px;
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
				background-color: rgba(0, 0, 0, 0.2);
				text-align: left;
				padding: 0 10px;
				box-sizing: border-box;
				text-align: center;
				position: absolute;
				bottom: 10%;
				z-index: 10;
			}
		}
		.text {
			flex: 1;
			overflow-y: auto;
			.text-line {
				width: 100%;
				height: 33.33%;
				display: flex;
				justify-content: flex-start;
				align-items: center;
				.date-text {
					height: 18px;
					line-height: 18px;
					margin-top: 2px;
					padding: 0 4px;
					background-color: #ddf1fb;
					color: #31b4f5;
					font-size: 12px;
					font-weight: 550;
					border-radius: 2px;
					overflow: hidden;
					font-family: YouSheBiaoTiHei;
				}
				.text {
					flex: 1;
					line-height: 22px;
					overflow: hidden;
					text-overflow: ellipsis;
					white-space: nowrap;
					padding-left: 6px;
					box-sizing: border-box;
				}
			}
		}
	}
}
.modify-content {
	width: 100%;
	margin: 0 auto;
	box-sizing: border-box;
	.detail-title {
		width: 100%;
		height: 32px;
		line-height: 32px;
		text-align: left;
		font-size: 16px;
		font-weight: 550;
	}
	.time-and-name {
		width: 100%;
		height: 20px;
		line-height: 20px;
		display: flex;
		justify-content: flex-start;
		margin: 10px 0 30px 0;
		span {
			height: 20px;
			font-size: 13px;
			color: #999;
			margin-right: 10px;
		}
	}
	.text {
		line-height: normal;
		p {
			line-height: 2;
		}
	}
}

.model-header {
	display: flex;
	box-sizing: border-box;
	padding-right: 30px;
	justify-content: space-between;
	align-items: center;
	line-height: 16px;
	cursor: pointer;
	.iconfont {
		font-size: 16px;
		box-sizing: border-box;
		padding: 0 5px;
	}
}
.four-news {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	.title {
		display: flex;
		height: 52px;
		padding: 0px var(--spacing-8, 16px) 0px var(--spacing-10, 20px);
		justify-content: space-between;
		align-items: center;
		color: var(--text-on-surface-primary, #181b22);

		/* web/cn/heading/heading-02 */
		font-family: 'PingFang SC';
		font-size: 16px;
		font-style: normal;
		font-weight: 500;
		line-height: 150%; /* 24px */
		letter-spacing: 0.5px;
		.more {
			color: var(--text-on-surface-tertiary, #707786);
			/* web/number/medium/helper-01 */
			font-family: 'JetBrains Mono NL';
			font-size: 12px;
			font-style: normal;
			font-weight: 500;
			line-height: 150%; /* 18px */
			display: flex;
			align-items: center;
		}
	}
	.list {
		display: flex;
		padding: var(--spacing-10, 20px);
		flex-direction: column;
		align-items: flex-start;
		flex: 1 0 0;
		align-self: stretch;
		border-radius: 16px;
		border: 1px solid rgba(255, 255, 255, 0.6);
		// background: linear-gradient(
		//   180deg,
		//   rgba(255, 255, 255, 0.24) 0%,
		//   rgba(255, 255, 255, 0.6) 100%
		// );
		background: #fff;
		backdrop-filter: blur(13px);
		overflow: auto;
		.image {
			width: 100%;
			height: 70%;
			position: relative;
			.image-comtainer {
				width: 100%;
				height: 100%;
				position: relative;
				overflow: hidden;
				background-color: #fff;
				box-sizing: border-box;
				padding: 0 !important;
				border-radius: 12px;
				box-shadow: 0px 4px 8px 0px rgba(25, 27, 35, 0.04), 0px 0px 1px 0px rgba(25, 27, 35, 0.16) inset,
					0px 8px 24px 0px rgba(25, 27, 35, 0.12);
				img {
					width: 100%;
					height: 100%;
				}
				.img-set {
					width: 100%;
					height: 100%;
					display: flex;
					justify-content: center;
					align-items: center;
					span {
						color: #999;
					}
				}
			}
			::v-deep(.el-carousel__indicators) {
				display: none;
			}
			.image-text {
				width: 100%;
				height: 22px;
				line-height: 22px;
				color: #fff;
				font-size: 14px;
				border-radius: 0 0 6px 6px;
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
				background-color: rgba(0, 0, 0, 0.2);
				text-align: left;
				padding: 0 10px;
				box-sizing: border-box;
				text-align: center;
				position: absolute;
				bottom: 10%;
				z-index: 10;
			}
		}
		.data {
			flex: 1;
			display: flex;
			flex-direction: column;
			overflow: auto;
			width: -webkit-fill-available;
			.item {
				display: flex;
				padding: var(--spacing-3, 6px);
				align-items: center;
				gap: var(--spacing-8, 16px);
				flex: 1 0 0;
				border-radius: var(--radius-3, 6px);
				align-items: center;
				&:hover {
					border: 1px solid #f9f6f4;
					border-radius: var(--radius-4, 8px);
					background: linear-gradient(
						180deg,
						rgba(247, 171, 25, 0) 2.19%,
						rgba(242, 119, 19, 0.1) 48.06%,
						rgba(231, 0, 6, 0.1) 98.44%
					);
				}
				.num {
					color: #f49b16;
					-webkit-text-stroke-width: 0.4000000059604645;
					-webkit-text-stroke-color: #f49b16;
					font-family: 'D-DIN Exp';
					font-size: 13px;
					font-style: italic;
					font-weight: 400;
					line-height: 20px; /* 153.846% */
					letter-spacing: 0.4px;
				}
				.text {
					flex: 1;
					overflow: hidden;
					white-space: nowrap;
					text-overflow: ellipsis;
					overflow: hidden;
					color: var(--text-on-surface-primary, #181b22);
					/* web/cn/helper/helper-03 */
					font-family: 'PingFang SC';
					font-size: 13px;
					font-style: normal;
					font-weight: 400;
					line-height: 20px; /* 153.846% */
					letter-spacing: 0.4px;
				}
				.date {
					overflow: hidden;
					color: var(--text-on-surface-placeholder, #a4acbd);
					text-overflow: ellipsis;

					/* web/cn/helper/helper-02 */
					font-family: 'PingFang SC';
					font-size: 12px;
					font-style: normal;
					font-weight: 400;
					line-height: 150%; /* 18px */
					letter-spacing: 0.4px;
				}
			}
			.item:nth-of-type(1) {
				.num {
					color: #e90d09;
				}
			}
			.item:nth-of-type(2) {
				.num {
					color: #f16412;
				}
			}
		}
	}
}
</style>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],class:{
    'waring-list-warp': true,
    'four-style': +_vm.systemConfig.theme_type === 4,
  },on:{"scroll":_vm.scrollEvent}},[_vm._l((_vm.getLocalizedData(_vm.list, [
      'warn_type',
      'current_name',
      'warn_content',
    ])),function(item,index){return _c('div',{key:index,staticClass:"list-item",on:{"click":function($event){return _vm.handleItemClick(item)}}},[_c('div',{staticClass:"title-warp"},[_c('span',{staticClass:"status",class:_vm.flterStatus(item.warn_level).class},[_vm._v(_vm._s(_vm.flterStatus(item.warn_level).text))]),_c('div',{staticClass:"line-title"},[_c('span',{staticClass:"title"},[_vm._v("【"+_vm._s(item.t_warn_type)+"】"+_vm._s(item.t_warn_content))])])]),_c('div',{staticClass:"info-warp"},[_c('span',{staticClass:"project-info"},[_vm._v(_vm._s(_vm.$t('todoCom.WarringList.792663-2'))+_vm._s(item.t_current_name ? item.t_current_name : '-'))]),_c('span',{staticClass:"time"},[_vm._v(_vm._s(_vm.filterTime(item.created_at)))])])])}),(_vm.list.length === 0)?_c('Empty',{attrs:{"description":_vm.$t('todoCom.TodoList.785059-1'),"image-size":100}}):_vm._e(),_c('div',{staticClass:"alert-dialog"},[(_vm.dialogVisible)?_c('alertDetailPop',{staticClass:"warning-detail",attrs:{"item":_vm.warringData},on:{"close-pop":function($event){_vm.dialogVisible = false}}}):_vm._e()],1)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }
<!--
    @name: CommonGridContainer1231
    @description：CommonGridContainer
    @author: ZengWei
    @date: 2022-06-22 14:21
-->
<template>
	<div class="container" :class="{ 'en-US': $i18n.locale === 'en' }">
		<!-- 编辑面板 -->
		<header class="header-container" v-if="judgingArchiAPermission([], ['secondscreen.update'])">
			<section class="edit display" @click="showEdit = !showEdit" v-show="!showEdit">
				<i class="custom-icon el-icon-edit towards-the-right"></i>
				{{ $t('editHomePage') }}
			</section>
			<section class="edit display" @click="comfirm" v-show="showEdit">
				<i class="custom-icon el-icon-upload towards-the-right"></i>
				{{ $t('saveHomePage') }}
			</section>
			<section class="add display" @click="add" v-show="showEdit">
				<i class="custom-icon el-icon-plus towards-the-right"></i>
				{{ $t('addDrawingBoard') }}
			</section>
		</header>
		<!-- 面板区 -->
		<body class="container-layout">
			<div class="wrapper">
				<header class="header"></header>
				<div
					class="grid-container"
					:style="{
						paddingTop: judgingArchiAPermission([], ['secondscreen.update']) ? '32px' : '0'
					}"
				>
					<grid-layout
						v-if="isInit"
						ref="gridlayout"
						:layout.sync="layout"
						:col-num="12"
						:row-height="itemHeight"
						:is-draggable="showEdit"
						:is-resizable="showEdit"
						:is-mirrored="false"
						:vertical-compact="true"
						:margin="[16, itemMargin]"
						:use-css-transforms="true"
					>
						<grid-item
							v-for="(item, index) in layout"
							:key="item.i"
							:x="item.x"
							:y="item.y"
							:w="item.w"
							:h="item.h"
							:i="item.i"
						>
							<!-- 面板 -->
							<div class="drawing-board" @drop="onDropDown($event, item.id)" @dragover="onDragover">
								<div class="drawing-layout">
									<!-- 设置 -->
									<div class="panel-title">
										<el-popover v-show="showEdit" placement="bottom-start" width="120px" trigger="hover">
											<i slot="reference" class="el-icon-setting more-action panel-icon-position"></i>
											<!-- 设置内容 -->
											<article>
												<section v-if="item.pageData" class="action-bar" slot="edit" @click="selectItem(index)">
													<i class="custom-icon el-icon-edit-outline"></i>{{ $t('index.GridComponent.540553-0') }}
												</section>
												<section class="action-bar">
													<el-popconfirm :title="$t('index.GridComponent.540553-1')" @confirm="del(index)">
														<span slot="reference">
															<i class="custom-icon el-icon-delete"></i>{{ $t('index.GridComponent.540553-2') }}
														</span>
													</el-popconfirm>
												</section>
											</article>
										</el-popover>
									</div>
									<div v-show="showEdit" class="separate"></div>
									<i v-if="!item.pageData" class="el-icon-plus plus-font-size" @click.stop="selectItem(index)"></i>
								</div>
								<component :style="showEdit ? 'pointer-events: none;' : ''" v-if="item.isWrite" :is="item.pageData">
								</component>
								<PageParser
									v-if="!item.isWrite"
									:pageUUID="item.pageData"
									:style="showEdit ? 'pointer-events: none;' : ''"
								>
								</PageParser>
							</div>
						</grid-item>
					</grid-layout>
				</div>
			</div>
			<!-- 面板内容选择 -->
			<el-drawer
				class="panel-rendered-content"
				:size="350"
				:title="$t('index.GridComponent.540553-3')"
				:visible.sync="drawer"
			>
				<Drawer
					:treeData="options"
					:layout="layout"
					:drawerIndex="drawerIndex"
					@back="back"
					@commit="getCommit"
					@reset="back"
				></Drawer>
			</el-drawer>
		</body>
	</div>
</template>

<script>
import { judgingArchiAPermission, deepCopy, getComponentId, uniqid, isJSONStr } from '@/utils/tools';
import { Popover, Popconfirm } from 'element-ui';
import Drawer from './GridDrawer';
import VueGridLayout from 'vue-grid-layout';
import PageParser from '@/components/parser/Index';
import componentList from '@/custom-component/component-list';
import { mapState } from 'vuex';
import { dataInterface } from '@/apis/data';
const componentsList = require.context('@/views/newSecond', true, /\.+vue$/);
const customComponents = {};
componentsList.keys().forEach((fileName) => {
	let name = fileName.replace(/.vue/, '');
	name = name.replace(/\.\//, '');
	name = name.split('/')[1];
	customComponents[name] = componentsList(fileName).default;
});
//中控台组件
const componentsConsole = require.context('@/console-components', true, /\.+vue$/);
componentsConsole.keys().forEach((fileName) => {
	let name = fileName.replace(/.vue/, '');
	name = name.replace(/\.\//, '');
	name = name.split('/')[1];
	customComponents[name] = componentsConsole(fileName).default;
});

export default {
	name: 'GridComponent',
	components: {
		...customComponents,
		Drawer,
		PageParser,
		'el-popover': Popover,
		'el-popconfirm': Popconfirm,
		GridLayout: VueGridLayout.GridLayout,
		GridItem: VueGridLayout.GridItem
	},
	props: {
		// 是否为预览
		isPreview: {
			type: Boolean,
			required: false,
			default: false
		}
	},
	data() {
		return {
			// 面板id
			keepId: null,
			// 选择面板的下标
			drawerIndex: null,
			// 选择面板抽屉
			drawer: false,
			pageData: null,
			showEdit: false,
			layout: [],
			// 手写页面数据 当新增手写页面时 需要在这里添加配置 uuid：手写页面name label：手写页面中文显示
			// 手写页面名称不能命名含有page！！！
			writeDatas: [
				{
					name: this.$t('index.GridComponent.540553-4'),
					uuid: 'todoCom',
					label: this.$t('index.GridComponent.540553-4')
				},
				{ name: this.$t('index.GridComponent.540553-5'), uuid: 'apps', label: this.$t('index.GridComponent.540553-5') },
				{
					name: this.$t('index.GridComponent.540553-6'),
					uuid: 'notice',
					label: this.$t('index.GridComponent.540553-6')
				},
				{
					name: this.$t('index.GridComponent.540553-7'),
					uuid: 'statistic',
					label: this.$t('index.GridComponent.540553-7')
				},
				{ name: this.$t('index.GridComponent.540553-8'), uuid: 'news', label: this.$t('index.GridComponent.540553-8') },
				{
					name: this.$t('index.GridComponent.540553-9'),
					uuid: 'safety',
					label: this.$t('index.GridComponent.540553-9')
				},
				{
					name: this.$t('index.GridComponent.540553-10'),
					uuid: 'schedule',
					label: this.$t('index.GridComponent.540553-10')
				},
				{
					name: this.$t('index.GridComponent.540553-11'),
					uuid: 'people',
					label: this.$t('index.GridComponent.540553-11')
				},
				{
					name: this.$t('index.GridComponent.540553-16'),
					uuid: 'buildView',
					label: this.$t('index.GridComponent.540553-16')
				},
				{
					name: this.$t('index.GridComponent.540553-17'),
					uuid: 'plalformDynamics',
					label: this.$t('index.GridComponent.540553-17')
				}
			],
			isInit: false,
			baseHeight: 792,
			itemHeight: 81,
			itemMargin: 16,
			// 选择渲染组件
			options: [{ label: this.$t('index.GridComponent.540553-3') }],
			compantId: JSON.parse(localStorage.getItem('VUE_APP_BASE_ID'))
			// gridHeight:''  // todo 是否需要储存保存者的高度？？
		};
	},
	computed: {
		...mapState([
			// 画布样式
			'canvasStyle',
			// 图层列表
			'componentData',
			// 是否点击组件
			'isClickComponent',
			'sContainer',
			// 大屏容器组件的元组件
			'originComponentData',
			// 当前架构
			'targetArchi'
		])
	},
	watch: {
		targetArchi: {
			handler(newV, oldV) {
				const targetArchiInit = sessionStorage.getItem('targetArchiInit');
				if (+targetArchiInit !== 1 && (!newV || !oldV)) return;
				this.layout = [];
				this.getData();
				sessionStorage.removeItem('targetArchiInit');
			},
			deep: true,
			immediate: true
		}
	},
	created() {
		this.getAllPages();
	},
	mounted() {
		this.$nextTick(() => {
			try {
				const box = document.querySelector('.grid-container');
				let defaultHeight = 81;
				const height = ((box.offsetHeight - 32) / this.baseHeight) * defaultHeight;
				const margin = ((box.offsetHeight - 32) / this.baseHeight) * 16;
				if (!isNaN(height)) {
					this.itemHeight = height;
				}
				if (!isNaN(margin)) {
					this.itemMargin = margin;
				}
			} catch (err) {
				console.log(err);
			}
			this.isInit = true;
		});
		window.onresize = () => {
			try {
				const box = document.querySelector('.grid-container');
				let defaultHeight = 81;
				const height = ((box.offsetHeight - 32) / this.baseHeight) * defaultHeight;
				const margin = ((box.offsetHeight - 32) / this.baseHeight) * 16;
				if (!isNaN(height)) {
					this.itemHeight = height;
				}
				if (!isNaN(margin)) {
					this.itemMargin = margin;
				}
				console.log('itemHeight', this.itemHeight);
			} catch (err) {
				console.log(err);
			}
			this.isInit = true;
		};
	},
	methods: {
		judgingArchiAPermission,

		getData() {
			let firm = {
				object_uuid: 'object62e22770a666e',
				view_uuid: 'view62e227954b0a9',
				__method_name__: 'dataList',
				transcode: 0
			};
			if (this.compantId) {
				firm.project_id = this.compantId;
			}
			dataInterface(firm)
				.then((res) => {
					if (!res || res.status !== 200) {
						//假如没数据 则变成默认数据
						this.layout = [
							{
								id: uniqid(),
								x: 0,
								y: 0,
								w: 4,
								h: 4,
								i: '0',
								pageData: 'news',
								isWrite: true
							},
							{
								id: uniqid(),
								x: 0,
								y: 4,
								w: 4,
								h: 4,
								i: '1',
								pageData: 'notice',
								isWrite: true
							},
							{
								id: uniqid(),
								x: 4,
								y: 0,
								w: 4,
								h: 4,
								i: '2',
								pageData: 'apps',
								isWrite: true
							},
							{
								id: uniqid(),
								x: 4,
								y: 4,
								w: 4,
								h: 4,
								i: '3',
								pageData: 'todoCom',
								isWrite: true
							},
							{
								id: uniqid(),
								x: 8,
								y: 0,
								w: 4,
								h: 8,
								i: '4',
								pageData: 'statistic',
								isWrite: true
							}
						];
						// console.log(this.layout, '----111---');
						return false;
					}
					if (res.data.data.data && res.data.data.data.length) {
						this.keepId = res.data.data.data[0].id;
						const layout = res.data.data.data[0].layoutData ?? res.data.data.data[0].layout;
						if (layout && layout.length) {
							this.layout = isJSONStr(layout) ? JSON.parse(layout) : layout;
							// console.log(this.layout, '----22222---');
						}
					}
					if (!this.layout.length) {
						this.layout = [
							{
								id: uniqid(),
								x: 0,
								y: 0,
								w: 4,
								h: 4,
								i: '0',
								pageData: 'news',
								isWrite: true
							},
							{
								id: uniqid(),
								x: 0,
								y: 4,
								w: 4,
								h: 4,
								i: '1',
								pageData: 'notice',
								isWrite: true
							},
							{
								id: uniqid(),
								x: 4,
								y: 0,
								w: 4,
								h: 4,
								i: '2',
								pageData: 'apps',
								isWrite: true
							},
							{
								id: uniqid(),
								x: 4,
								y: 4,
								w: 4,
								h: 4,
								i: '3',
								pageData: 'todoCom',
								isWrite: true
							},
							{
								id: uniqid(),
								x: 8,
								y: 0,
								w: 4,
								h: 8,
								i: '4',
								pageData: 'statistic',
								isWrite: true
							}
						];
						// console.log(this.layout, '----33333---');
					}
				})
				.catch(() => {});
		},
		/**
		 * @desc: 添加面板（计算当前layout的最后一位在最后一位后进行添加）
		 */
		add() {
			let arr = [];
			this.layout.forEach((r) => {
				arr.push(+r.i);
			});
			arr.sort((a, b) => {
				return a - b;
			});
			const max = arr[arr.length - 1];
			const last = this.layout[this.layout.length - 1];
			let x = 0;
			if (last.x < 9) {
				x = last.x + 3;
			}
			const newPanel = {
				id: uniqid(),
				x: x,
				y: last.y + 6,
				w: 4,
				h: 4,
				i: '' + (max + 1)
			};
			this.layout.push(newPanel);
		},
		/**
		 * @desc: 删除面板
		 */
		del(index) {
			this.layout.splice(index, 1);
		},
		/**
		 * @desc: 放置拖放
		 */
		onDropDown(e, gridItemId) {
			e.stopPropagation();
			e.preventDefault();
			if (this.isPreview) return;
			const groupIndex = e.dataTransfer.getData('group-index');
			const comIndex = e.dataTransfer.getData('index');
			if (isNaN(groupIndex) || isNaN(comIndex) || !Array.isArray(componentList) || !componentList.length) {
				this.$message.error(this.$t('index.GridComponent.540553-12'));
				return;
			}
			const group = componentList[groupIndex];
			if (!group || !group.componentList || !Array.isArray(group.componentList)) {
				this.$message.error(this.$t('index.GridComponent.540553-12'));
				return;
			}
			const component = deepCopy(group.componentList[comIndex]);
			component.id = getComponentId(component.component);
			component.parentId = '';
			// 组件嵌套方式
			const target = e.target;
			let containerId = null;
			if (target instanceof SVGElement || target?.className?.includes('normal')) {
				console.log(this.$t('index.GridComponent.540553-13'));
			} else {
				// 1、当前放置位置为容器则在容器中追加
				// 2、获取当前放置节点的第一个祖先容器并放置
				const parentContainer = this.getParentContainer(target);
				if (parentContainer) {
					containerId = parentContainer.containerId;
					component.parentId = containerId;
				}
			}
			// 将组件写入GridItem
			component.gridItemUuid = gridItemId;
			// 将组件写入到图层列表
			this.$store.commit('modifyComponent', { component, containerId });
			// 添加快照
			this.$store.commit('recordSnapshot');
			e.dataTransfer.setData('group-index', -1);
			e.dataTransfer.setData('index', -1);
		},
		/**
		 * @desc: 拖动进入放置区域
		 */
		onDragover(e) {
			e.stopPropagation();
			e.preventDefault();
			if (this.isPreview) return;
			e.dataTransfer.dropEffect = 'copy';
		},
		/**
		 * @desc: 获取距离当前节点最近的容器ID
		 * @param {Object} target 当前节点
		 * @return {String} 返回节点树上距离当前节点最近的容器ID
		 */
		getParentContainer(target) {
			if (target?.dataset?.id) {
				return {
					target,
					containerId: target.dataset.id
				};
			}
			return this.getParentContainer(target.parentNode);
		},
		/**
		 * 获取所有的页面数据 （select框内容）
		 */
		getAllPages() {
			let param = {
				object_uuid: 'a4f016d6-c602-4492-8874-f088c3c0b3b9',
				view_uuid: 'view61922b1881103',
				search: [
					{
						field_uuid: 'field61bc41f645e17',
						ruleType: 'like',
						value: 'second'
					}
				],
				__method_name__: 'dataList',
				transcode: 0,
				page: 1,
				size: 20
			};
			dataInterface(param)
				.then((res) => {
					if (!res || res.status !== 200) {
						return false;
					}
					this.options[0].children = [];
					this.writeDatas.forEach((flag) => {
						this.options[0].children.push(flag);
					});
					const list = res?.data?.data?.data || [];
					list.forEach((ele) => {
						this.options[0].children.push({
							...ele,
							label: ele.name
						});
					});
				})
				.catch((err) => {
					console.log(err);
				});
		},
		/**
		 * @desc: 保存新增/编辑面板
		 */
		comfirm() {
			if (this.keepId) {
				this.updateData();
			} else {
				this.createData();
			}
			this.showEdit = false;
		},
		/**
		 * @desc: 修改面板
		 */
		updateData() {
			let firm = {
				object_uuid: 'object62e22770a666e',
				view_uuid: 'view62e227954b0a9',
				__method_name__: 'updateData',
				data_id: this.keepId,
				layoutData: JSON.stringify(this.layout),
				transcode: 0,
				project_id: this.compantId
			};
			dataInterface(firm).then((res) => {
				if (!res || res.status !== 200) {
					this.$message.success(this.$t('index.GridComponent.540553-14'));
					return false;
				}
				this.$message.success(this.$t('index.GridComponent.540553-15'));
			});
		},
		/**
		 * @desc: 新增面板
		 */
		createData() {
			let firm = {
				object_uuid: 'object62e22770a666e',
				view_uuid: 'view62e227954b0a9',
				__method_name__: 'createData',
				layoutData: JSON.stringify(this.layout),
				transcode: 0,
				project_id: this.compantId
			};
			dataInterface(firm).then((res) => {
				if (!res || res.status !== 200) {
					this.$message.success(this.$t('index.GridComponent.540553-14'));
					return false;
				}
				this.$message.success(this.$t('index.GridComponent.540553-15'));
				this.getData();
			});
		},
		/**
		 * @desc: 选择面板渲染内容
		 */
		selectItem(index) {
			if (!this.showEdit) return;
			this.drawer = true;
			this.drawerIndex = index;
		},
		/**
		 * @desc: 确认选择
		 */
		getCommit(draweruuid, val) {
			this.layout[this.drawerIndex].isWrite = val;
			this.layout[this.drawerIndex].pageData = draweruuid;
			this.drawer = false;
		},
		back() {
			this.drawer = false;
		}
	}
};
</script>

<style lang="less" scoped>
.container {
	width: 100%;
	height: 100%;
	.header-container {
		display: flex;
		flex-direction: row-reverse;
		position: fixed;
		width: 100%;
		height: 32px;
		color: var(--themeColor);
		z-index: 1;
		.edit {
			width: 126px;
			height: 32px;
			margin-right: 16px;
			background: #ffffff;
			border-radius: 8px;
			.towards-the-right {
				padding-right: 9px;
				box-sizing: border-box;
			}
		}
		.display {
			display: flex;
			justify-content: center;
			align-items: center;
		}
		.add {
			width: 112px;
			height: 32px;
			margin-right: 16px;
			background: #ffffff;
			border-radius: 8px;
			.towards-the-right {
				padding-right: 9px;
				box-sizing: border-box;
			}
		}
	}
	.container-layout {
		overflow-y: auto;
		width: 100%;
		height: 100%;
		background: transparent;
		scrollbar-width: none;
		.wrapper {
			position: relative;
			overflow-y: auto;
			width: 100%;
			height: 100%;
			scrollbar-width: none;
		}
		.panel-rendered-content {
			z-index: 10000;
		}
		.header {
			position: absolute;
			top: -18px;
			right: 0;
			height: 35px;
		}
		.grid-container {
			height: 100%;
			width: 100%;
			box-sizing: border-box;
			.drawing-board {
				width: 100%;
				height: 100%;
				.drawing-layout {
					position: absolute;
					display: flex;
					flex-direction: row-reverse;
					justify-content: space-between;
					width: 100%;
					z-index: 99999;
					.panel-title {
						position: relative;
						padding-right: 35px;
						font-size: 14px;
						line-height: 40px;
						text-align: left;
						text-indent: 10px;
						p {
							overflow: hidden;
							white-space: nowrap;
							text-overflow: ellipsis;
						}
						.more-action {
							position: absolute;
							top: 12px;
							right: 12px;
							cursor: pointer;
						}
					}
					.separate {
						position: absolute;
						right: 18px;
						top: 22px;
						width: 20px;
						height: 20px;
					}
					.plus-font-size {
						font-size: 25px;
					}
					.panel-icon-position {
						right: 5px;
						margin-top: -9px;
						font-size: 20px;
					}
				}
			}
		}
	}
}
.action-bar {
	width: 100%;
	height: 100%;
	padding: 6px 8px 6px 12px;
	box-sizing: border-box;
	font-size: 14px;
	border-radius: 6px;
	&:hover {
		background-color: #f2f5ff;
		color: #5b7af6;
	}
	.custom-icon {
		margin-right: 8px;
		font-size: 16px;
	}
}
:deep(.vue-grid-layout .vue-grid-item:hover) {
	border: 1px solid #eee;
}
:deep(.normal) {
	border-radius: 8px;
}
:deep(.vue-grid-layout) {
	.vue-grid-item {
		border-radius: 8px;
		border: 1px solid #eeeeee;
		transition: all 0.3s;
		.parser {
			background: transparent !important;
		}

		.vue-resizable-handle {
			padding: 0 5px 5px 0;
		}
		&:hover {
			border: 1px solid #adbeff;
		}
	}
	.vue-grid-placeholder {
		background-color: #adbeff;
		border: 1px dashed #0000cd !important;
	}
}
.container.en-US {
	.header-container {
		.edit {
			width: 150px;
		}
		.add {
			width: 180px;
		}
	}
}
</style>
